/* Requires
   globals.css */

/* Standard Forms ---------------------- */

form {
  margin: 0 0 ($form-spacing);
}

.row form .row {
  margin: 0 ($form-spacing / -2);

  .column,
  .columns {
    padding: 0 ($form-spacing / 2);
  }

  &.collapse {
    margin: 0;

    .column,
    .columns {
      padding: 0;
    }
  }
}

label {
  font-size: ms(0);
  color: $label-font-color;
  cursor: pointer;
  display: block;
  font-weight: $label-font-weight;
  margin-bottom: $label-bottom-margin;

  &.right {
    float: none;
    text-align: right;
  }

  &.inline {
    line-height: (ms(0) + ($form-spacing * 1.5));
    margin: 0 0 $form-spacing;
  }
}

.postfix,
.prefix {
  display: block;
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  height: (ms(0) + ($form-spacing * 1.5));
  line-height: (ms(0) + ($form-spacing * 1.5)) - 1;
}

a.button.postfix,
a.button.prefix {
  padding-#{$default-float}: 0;
  padding-#{$default-opposite}: 0;
  text-align: center;
}

span.postfix,
span.prefix {
  background: darken($white, 5%);
  border: 1px solid darken($white, 20%);
}

.prefix {
  #{$default-float}: 2px;
  border-top-#{$default-float}-radius: 2px;
  border-bottom-#{$default-float}-radius: 2px;
  overflow: hidden;
}

.postfix {
  #{$default-opposite}: 2px;
  border-top-#{$default-opposite}-radius: 2px;
  border-bottom-#{$default-opposite}-radius: 2px;
}

input[type='date'],
input[type='datetime'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
textarea,
select {
  background-color: $input-bg-color;
  font-family: inherit;
  border: $input-border-width $input-border-style $input-border-color;
  border-radius: $input-border-radius;
  color: $input-font-color;
  font-weight: $input-font-weight;
  display: block;
  font-size: $input-font-size;
  margin: 0 0 $form-spacing;
  padding: $input-padding;
  width: 100%;
  transition: all .3s linear;

  &:focus {
    background: $input-focus-bg-color;
    border-color: $input-focus-border-color;
  }

  &[disabled] {
    background-color: $gray-dark;
    cursor: default;
  }
}

textarea {
  height: auto;
}

select {
  width: 100%;
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  cursor: pointer;
  padding-right: $select-caret-width + 10;
}

select::-ms-expand {
  display: none !important;
}

.select {
  position: relative;
  cursor: pointer;
  max-width: 100%;

  .views-exposed-form & {
    width: 250px;
  }

  &:after {
    @include extend-icon(caret-down);

    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: $select-caret-width;
    color: $select-caret-color;
    text-align: center;
    font-size: 1rem;
    line-height: 2.8 !important;
    border-left: $select-caret-border-width solid $input-border-color;
    padding-right: .2em;
  }
}

// Fieldsets
fieldset {
  border: solid 1px $gray-dark;
  border-radius: $fieldset-border-radius;
  padding: 12px;
  margin: 18px 0;

  legend {
    font-weight: bold;
    background: $white;
    padding: 0 3px;
    margin: 0;
    margin-#{$default-float}: -3px;
  }
}

::-webkit-input-placeholder { // Chrome/Opera/Safari
  font-weight: bold;
}

::-moz-placeholder { // Firefox 19+
  font-weight: bold;
}

:-ms-input-placeholder { // IE 10+
  font-weight: bold;
}

:-moz-placeholder { // Firefox 18-
  font-weight: bold;
}

/* Errors */
.error input,
.error input:focus,
.error textarea,
.error textarea:focus,
input.error,
input.error:focus,
textarea.error,
textarea.error:focus {
  border-color: $alert-color;
  background-color: rgba($alert-color, 0.1);
}

.error input:focus,
.error textarea:focus,
input.error:focus,
textarea.error:focus {
  outline-color: lighten($alert-color, 30%);
}

.error label,
label.error {
  color: $alert-color;
}

.error small,
small.error {
  display: block;
  padding: 6px 4px;
  margin-top: -($form-spacing) - 1;
  margin-bottom: $form-spacing;
  background: $alert-color;
  color: $white;
  font-size: ms(0) - 2;
  font-weight: bold;
  border-bottom-#{$default-float}-radius: $input-border-radius;
  border-bottom-#{$default-opposite}-radius: $input-border-radius;
}

/* Correct FF custom dropdown height */
@-moz-document url-prefix() {
  form.custom div.custom.dropdown a.selector {
    height: 28px;
  }
}

.lt-ie9 form.custom div.custom.dropdown a.selector {
  height: 28px;
}

.webform-client-form .form-item {
  @include clearfix();

  label.option {
    font-weight: normal;
    font-size: 90%;

    a.privacy-link {
      text-decoration: underline;
    }
  }

  .form-checkbox {
    float: left;
    margin: 8px 8px 0;
  }
}

.section-doneren .pane-payment-donation form .form-wrapper {
  > .form-item.form-type-checkbox {
    margin: 20px 0;

    > .form-checkbox {
      margin: 8px 5px 0 5px;
    }

    > label.option {
      font-weight: normal;
    }



    > .description {
      margin-left: 26px;

      a.privacy-link {
        text-decoration: underline;
      }
    }
  }
}
