//================================================
//=== AGENDA =======================================
//================================================

$agenda-block-gutter:       50px;
$agenda-list-gutter:        15px;


.agenda-overzicht .item {
  padding: 20px 0 0;
  margin: 20px 0 0;
  border-top: 1px solid #EFEFEF;

  .item:first-child {}

  h2 {
    margin-top: 0;
  }
}

.agenda-detail .datum,
.agenda-overzicht .datum {
  margin: 0 0 10px;
}

.agenda-detail .datum .datum-label,
.agenda-overzicht .datum .datum-label {
  font-weight: bold;
}

.agenda-widget {
  .widget-item {
    margin: 0 0 20px;
  }

  .agenda-title {
    font-weight: bold;
  }
}

.node-type-agenda .pane-node-created {
  display: none !important;
}


/*! Header Title styles */
.agenda {
  > .title-wrapper h1 {
    position: relative;

    &::after {
      content: '';
      width: 100px;
      height: 100px;
      display: block;
      position: absolute;
      right: 0;
      top: calc(50% - 50px);
      background: $aubergine url('../images/icon-agenda.svg') no-repeat center;
      background-size: 45%;
      border-radius: 50%;

      @include breakpoint(medium) {
        width: 50px;
        height: 50px;
        top: calc(50% - 25px);
      }
    }
  }
}


/*! Content Pane styles */
.content {
  > .pane-agenda {
    margin-top: $agenda-block-gutter;
  }
}

.agenda-block {
  width: 49%;
  display: inline-block;
  border-bottom: 1px solid $gray-light;
  margin-bottom: $agenda-block-gutter;

  &:first-child {
    float: right;
    margin-left: 2%;
  }

  > h3 {
    display: block;
    border-bottom: 1px solid $gray-light;
    color: $teal;
    padding-bottom: $agenda-list-gutter;
    margin-bottom: $agenda-list-gutter;
  }

  @include breakpoint(small) {
    width: 100%;

    &:first-child {
      float: none;
      margin-left: 0;
    }
  }
}

.agenda-list--item {
  position: relative;

  &::before {
    position: absolute;
    left: 5px;
    top: 3px;
  }

  > h2 {
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
  }

  & .datum,
  & .date-display-range {
    font-size: 14px;
    color: $blue;
  }
}


/*! Detail styles */
.agenda-detail .banner {
  margin-bottom: $agenda-block-gutter;

  @include breakpoint(small) {
    margin-bottom: $agenda-list-gutter;
  }
}

.agenda-detail__header-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  position: relative;

  & .field-item p {
    font-family: $alternative-font-light;
    font-size: 24px;
    line-height: 24px;
    color: #FFF;

    @include breakpoint(small) {
      font-size: 14px;
      line-height: 14px;
    }

    & strong {
      display: block;
      font-family: $alternative-font;
      font-size: 38px;
      font-weight: normal;
      line-height: 45px;
      letter-spacing: 2px;

      @include breakpoint(small) {
        font-size: 32px;
        line-height: 32px;
      }
    }
  }
}

.agenda-detail__category {
  & .field-item {
    font-family: $alternative-font-light;
    font-size: 24px;
    color: $aubergine;
  }
}

.agenda-detail__date {
  & .field-item {
    margin: 5px 0;
    font-weight: bold;
    color: $teal;
  }
}

.agenda-detail__location {
  & .field-item {
    color: $teal;
  }
}

.agenda-detail__content {
  margin: ($agenda-block-gutter / 2) 0;

  @include breakpoint(small) {
    margin: $agenda-list-gutter 0;
  }
}

.agenda-detail__webform {
  margin-top: $agenda-block-gutter;

  .content & {
    table tbody tr td {
      padding: 0;
      border: 0;
    }

  }
}

.agenda-detail__back .btn-back {
  margin-top: 0;
}


/*! Program styles */
.program__item {
  border-top: 1px solid $gray-light;

  &:first-child > * {
    font-family: $alternative-font;
    font-weight: normal;
    color: $teal;
  }

  &:last-child {
    border-bottom: 1px solid $gray-light;
  }

  @include breakpoint(medium) {
    min-height: 125px;
    position: relative;
    padding: $agenda-list-gutter;

    &:first-child {
      display: none;
    }

    &:nth-child(odd) {
      background: $gray-light;
    }
  }
}

.program__time,
.program__name,
.program__speaker,
.program__image {
  display: inline-block;
  padding: 5px 15px 5px 1px;
  float: left;

  > .label {
    display: none;
  }

  @include breakpoint(medium) {
    padding: 0 1px;
  }
}

.program__time {
  width: 200px;
  font-weight: bold;
}

.program__name {
  width: 300px;
}

.program__speaker {
  width: 300px;
}

.program__image {
  width: 120px;
  float: right;

  & img {
    border-radius: 50%;
    border: 1px solid $gray-medium;
  }
}

@include breakpoint(medium) {
  .program__time,
  .program__name,
  .program__speaker {
    width: 100%;
    display: block;
    float: none;

    > .label {
      width: 130px;
      display: block;
      font-family: $alternative-font;
      font-weight: normal;
      color: $teal;
      float: left;
    }

    > .content {
      display: block;
      margin: 0 130px;
    }
  }

  .program__image {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    float: none;
  }
}

@include breakpoint(small) {
  .program__time,
  .program__name,
  .program__speaker {

    > .label {
      float: none;
    }

    > .content {
      margin: 0 130px $agenda-list-gutter 0;
    }
  }
}


/*! Overwrites */
.row.agenda-detail__header-title {
  padding: 0 15px;
  padding-right: 35%;
}

.content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container):not(.pager).agenda-list {
  padding-left: $agenda-list-gutter;
  padding-right: $agenda-list-gutter * 2;
  margin-bottom: 0;
}

.content ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container):not(.pager) li.agenda-list--item {
  margin-bottom: $agenda-list-gutter * 2;

  &:last-child {
    margin-bottom: $agenda-list-gutter;
  }

  &::before {
    color: $aubergine;
  }
}
