.grid-cards:not(.agenda-overzicht) {
  margin: 4em 0 0;
}

a.grid-card:hover {
  text-decoration: none;

  .grid-card-title {
    text-decoration: underline;
  }

  .grid-card-title,
  .link {
    color: $tertiary-color;
  }
}

.grid-card {
  @extend %clearfix;

  display: block;
  padding: 2em 0;
  border-bottom: 1px solid $gray;

  .row-first &,
  .views-row-first & {
    border-top: 1px solid $gray;
  }

  .grid-card-image {
    width: $grid-card-image-size;
    float: left;

    img {
      width: $grid-card-image-size;
      height: $grid-card-image-size;
      border-radius: $grid-card-image-size / 2;
      overflow: hidden;
      border: 1px solid $gray-dark;
    }
  }

  .grid-card-content {
    width: 100%;
    float: right;
    padding: 1em 2em 3em;
    position: relative;
  }

  .grid-card-image + .grid-card-content {
    width: calc(100% - #{$grid-card-image-size});
  }

  .grid-card-title {
    margin: 0;
  }

  .grid-card-link {
    position: absolute;
    bottom: 1em;
    left: 2em;

    .link,
    a {
      font-weight: bold;
      font-size: .8em;
      color: $secondary-color;

      &:before {
        @include extend-icon(caret-right);
        display: inline-block;
      }

      [class^='icon-'],
      [class*=' icon-'] {
        display: inline-block;
        margin: 0 .3em;
      }
    }

    .file {
      display: flex;
      align-items: baseline;

      a {
        order: 1;
      }

      .file-icon {
        order: 2;
        margin: 0 .3em 0 .5em;
        align-self: center;
      }

      .file-size {
        font-size: .7em;
        order: 3;
      }
    }
  }

  .grid-card-title,
  .link,
  a {
    @include transition(color);
  }

  &.no-image {
    .grid-card-content {
      width: auto;
      float: none;
      padding: {
        left: 0;
        right: 0;
      }
    }

    .grid-card-description {
      padding-top: 15px;
    }

    .grid-card-link {
      left: 0;
    }
  }
}
