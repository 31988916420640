//================================================
//=== HEADER =====================================
//================================================
.content-top {
  margin-top: 0;
}

body {
  padding-top: 213px;

  @include breakpoint(medium) {
    padding-top: 80px;
  }

  &.scrolled {
    @include breakpoint(medium) {
      #logo {
        height: 40px;
      }
    }

    @include breakpoint(medium, 'up') {
      .header {
        padding-top: $header-padding / 2;
      }

      .hoofdmenu {
        margin-top: $header-padding / 2;

        .menu-link {
          padding: 10px 0;
        }
      }
    }
  }
}

.hoofdmenu {
  @include transition(margin-top);

  .menu-link {
    @include transition(padding);
  }
}

.header {
  padding: $header-padding 0 0;
  background-color: $white;
  // position: relative;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  @include transition(padding-top);

  .admin-menu & {
    top: 59px;

    @include breakpoint(small) {
      top: 77px;
    }
  }

  @include breakpoint(medium) {
    padding: ($column-gutter / 2) 0;
  }
}

.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#logo {
  display: block;
  height: 60px;

  img {
    height: 100%;
  }
}

.block.zoekveld {
  position: relative;
  vertical-align: middle;

  form {
    margin: 0;
    padding: 0;
  }

  input[type='text'],
  input[type='search'] {
    margin: 0;
    background-color: $gray;
    font-weight: normal;
    padding: 10px 30px 10px 15px;
    border: 1px solid $gray;
  }

  input[type='submit'],
  button {
    font-family: $icon-font;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    background: none;
    color: $input-font-color;
    border-radius: 0;
  }
}

#autocomplete {
  border: 1px solid $gray-dark;
  border-top: 0;
  width: 300px!important;
}

#autocomplete ul li {
  padding: 5px 10px;
  border-bottom: 1px solid $gray-dark;
}

#autocomplete ul li:hover {
  background: $gray-dark;
  cursor: pointer;
}
@include breakpoint(medium) {
  .search-form {
    display: none;
  }
}

.language-switcher {
  white-space: nowrap;

  .language {
    display: inline-block;
    font-size: .8em;

    &:not(.last):after {
      content: ' |';
      margin: 0 .3em;
      font-size: inherit;
    }

    &:after,
    .switch-language {
      color: $gray-dark;
    }

    .switch-language.active {
      color: $primary-color;
    }
  }
}
