// $button-color: $teal;

html .section-vrienden-van-ciro {
  padding-top: 0;
  &.admin-menu {
    margin-top: 0 !important;
  }
}

.landingspage {
  .row {
    width: 1030px;
  }

  p {
    font-size: 14px;
    line-height: 25px;
  }

  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 25px;
  }

  h1 {
    font-size: 52px;
    line-height: 58px;
  }

  h2 {
    font-size: 43px;
    line-height: 51px;
    color: #5a234a;
  }

  h3 {
    font-size: 26px;
    line-height: 32px;
  }

  h4 {
    font-size: 22px;
    line-height: 28px;
    color: #5a234a;
  }
}

.lp-section {
  padding: 30px 0;
  position: relative;
  margin: 0 0 60px;
  display: block;
}

.lp-content {
  padding-bottom: 0;
  margin-bottom: 0;
  background-image: url('../images/Agda_Rietje.jpg');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: bottom;
  // height: 800px;
  height: 100vh;
  min-height: 750px;
  margin-bottom: -110px;

  .mob-overlay {
    display: none;
    background: rgba(255,255,255,.8);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .lp-logo {
    position: relative;
    left: -25px;

    a {
      height: 71px;
      display: block;
      height: 60px;
    }
    img {
      height: 100% !important;
    }
  }

  .twelve.columns {
    width: 50%;
    margin-right: 50px;
  }
}

.lp-content-inner {
  margin-top: 115px;

  p {
    font-size: 15px;
    line-height: 27px;
    color: $blue;
    font-family: $alternative-font;
  }
  .btn {
    font-family: $default-font;
  }
}

.lp-purple {
  background-color: $primary-color;
  // padding: 30px 0;
  padding: 0;
  margin-bottom: 0;

  p {
    color: $white;
    font-size: 18px;
    line-height: 110px;
    font-weight: bold;
  }

  .btn {
    margin-left: 40px;
    line-height: 25px;
  }
}

.lp-friend {
  margin-top: 60px;
  margin-bottom: 0;

  p {
    font-weight: bold;
  }
}

.lp-blocks {
  .block {
    width: 33.3333%;
    display: inline-block;
    float: left;
    padding: 3px;

    .bean-landingspagina-blok {
      background: $teal;
      padding: 30px;
      padding-bottom: 80px;
      position: relative;

      h3 {
        color: $white;
      }

      p {
        font-weight: bold;
        line-height: 21px;
      }

      .btn {
        position: absolute;
        bottom: 10px;
      }
    }
  }
}

.lp-slider {
  background-color: #ccc;
  background-image: url('../images/CIRO_Annemiek.jpg');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-size: cover;
  height: 490px;
  padding: 60px 0;

  .block {
    &:first-child {
      padding-right: 25px;
    }

    .bean-landingspagina-blok {
      background-color: $white;
      padding: 30px;
      border-radius: 30px;
    }
  }
}

.lp-footer {
  .block {
    width: 50%;
    display: inline-block;
    float: left;

    &:first-child {
      padding-right: 35px;
      .bean-landingspagina-blok {
        padding: 30px;
        background-color: $teal;
        border-radius: 30px;

        h4 {
          color: $white;
        }
      }
    }

    &:last-child {
      padding-left: 35px;
    }
  }

  #mc_embed_signup {
    margin-top: 30px;
    form {
      padding: 0;
      margin: 0;
    }
    input.email {
      background: #53c3cf;
      border-radius: 0;
      color: $white;
      padding: 10px;
      border: 1px solid #20909c;
      font-weight: 400;

      &:focus {
        background: #53c3cf;
      }

      &::placeholder {
        color: $white;
        font-weight: 400;
      }
    }
  }

  .icon-phone,
  .icon-mail {
    color: #5a234a;
    margin-right: 10px;
  }
}

@media screen and (max-width:1023px) {
  .landingspage {
    h1,
    h2,
    h3,
    h4 {
      margin-bottom: 15px;
    }

    h1 {
      font-size: 36px;
      line-height: 42px;
    }
    h2 {
      font-size: 32px;
      line-height: 38px;
    }
    h3 {
      font-size: 19px;
      line-height: 25px;
    }
  }

  .lp-section {
    padding: 15px 0;
    margin: 0 0 30px;
  }

  .lp-content {
    padding-bottom: 0;
    margin-bottom: 0;

    .lp-logo {
      left: 0;
    }
  }

  .lp-content-inner {
    margin-top: 65px;
  }

  .lp-blocks {
    .block {
      .bean-landingspagina-blok {
        padding: 30px 20px;
        padding-bottom: 80px;

        p {
          line-height: 21px;
        }
      }
    }
  }

  .lp-slider {
    padding: 30px 0;

    .block {
      &:first-child {
        padding-right: 5px;
      }

      .bean-landingspagina-blok {
        padding: 30px 20px;
      }
    }
  }

  .lp-footer {
    .block {
      &:first-child {
        padding-right: 15px;
        .bean-landingspagina-blok {
          padding: 30px 20px;

          h4 {
            color: $white;
          }
        }
      }

      &:last-child {
        padding-left: 15px;
      }
    }
  }
}

@media screen and (max-width:767px) {
  .landingspage {
    h1 {
      font-size: 22px;
      line-height: 28px;
    }
    h2 {
      font-size: 18px;
      line-height: 24px;
    }
    h3 {
      font-size: 16px;
      line-height: 22px;
    }

    h4 {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .lp-section {
    margin: 0 0 15px;
  }

  .lp-content {
    &:before {
      content: '';
    }
    padding-bottom: 15px;
    margin-bottom: 0;
    height: auto;

    .twelve.columns {
      margin-right: 200px;
    }
  }

  .lp-content-inner {
    margin-top: 45px;
  }

  .lp-purple {
    .btn {
      margin-left: 20px;
    }
    p {
      line-height: 25px;
    }
  }

  .lp-blocks {
    .block {
      .bean-landingspagina-blok {
        padding: 20px;
        padding-bottom: 80px;
      }
    }
  }

  .lp-slider {
    height: auto;

    .block {
      &:first-child {
        padding-right: 250px;
      }

      .bean-landingspagina-blok {
        padding: 20px;
      }
    }
  }

  .lp-footer {
    .block {
      &:first-child {
        .bean-landingspagina-blok {
          padding: 20px;
        }
      }
    }
  }
}
@media screen and (max-width:660px) {
  .lp-purple {
    text-align: center;

    .btn {
      margin: 20px 0;
      margin-left: 0;
      display: block;
    }
  }
}
@media screen and (max-width:475px) {
  .landingspage {

    h1 {
      font-size: 22px;
      line-height: 28px;
    }
    h2 {
      font-size: 18px;
      line-height: 24px;
    }
    h3 {
      font-size: 16px;
      line-height: 22px;
    }

    h4 {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .lp-section {
    margin: 0 0 15px;
  }

  .lp-content {
    &:before {
      content: '';
    }
    padding-bottom: 15px;
    margin-bottom: 0;
    height: auto;

    min-height: 550px;
    background-position-x: 100%;
    background-size: cover;

    .mob-overlay {
      display: block;
    }

    .twelve.columns {
      margin-right: 0;
    }
  }

  .lp-content-inner {
    margin-top: 45px;
  }

  .lp-purple {
    text-align: center;

    .btn {
      margin: 20px 0;
      margin-left: 0;
      display: block;
    }
  }

  .lp-blocks {
    .block {
      width: 100%;
      padding: 0 0 3px;

      .bean-landingspagina-blok {
        padding: 20px;
        padding-bottom: 80px;
      }
    }
  }

  .lp-slider {
    height: auto;

    .block {
      &:first-child {
        padding-right: 0;
      }

      .bean-landingspagina-blok {
        padding: 20px;
      }
    }
  }

  .lp-footer {
    .block {
      width: 100%;
      &:first-child {
        padding-right: 0;
        padding-bottom: 45px;
        .bean-landingspagina-blok {
          padding: 20px;
        }
      }

      &:last-child {
        padding-left: 0;
      }
    }
  }
}
