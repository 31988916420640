.section-doneren {
  .pane-payment-donation {
    // margin: 4em 0;
    //
    // @media screen and (max-width: 767px) {
    //   margin: 2em 0;
    // }

    form {
      .form-wrapper {
        // display: block;

        > #field-emailveld-add-more-wrapper > div > .form-item > label,
        > div > .form-item > label,
        >  .form-item > label {
          font-weight: 600;
        }
      }

      pre {
        white-space: normal;
      }
    }

    #edit-payment-method-pmid-title {
      display: none;
    }

    .form-item-payment-donation-amount > label {
      display: none;
    }
    .form-item-payment-donation-amount-amount {
      width: 95%;
      display: inline-block;
      padding-left: 10px;
    }
  }

  .form-submit {
    appearance: none;
  }

  hr {
    border-color: $aubergine;
    border-width: 2px;
  }

  .pane-bean-footer-donatie {
    margin-top: 60px;
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
  }

  .media_embed {
    margin-bottom: 0;
  }

  .title-wrapper {
    background-image: url('../images/donatie-banner.jpg');
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
    height: 545px;
    max-height: 545px;
    margin-bottom: 0;
    @media screen and (max-width: 1023px) {
      .five {
        width: 50% !important;
      }
    }
    @media screen and (max-width: 767px) {
      height: 310px;
      max-height: 310px;
      .five {
        width: 100% !important;
      }
    }

    h1 {
      font-size: 92px;
      line-height: 97px;
      padding: 75px 0 50px;

      @media screen and (max-width: 767px) {
        font-size: 48px;
        line-height: 55px;
        padding: 40px 0 30px;
      }
    }

    p {
      font-size: 18px;
      line-height: 26px;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        font-size: .8rem;
        line-height: inherit;
      }
    }

    h1,
    p {
      color: $white;
    }
  }

  .form-type-radio input {
    margin-top: 0.3em;
    font-size: 17px;
  }

  .container.content {
    input[type="text"],
    input[type="email"] {
      border-radius: 10px;
      border-color: $black;
      padding: 8px 20px;
      max-width: 410px;
      position: relative;
      left: -5px;
    }
  }

  .form-submit {
    font-size: 17px;
    padding: 9px 30px;
  }

  .gray-bg {
    background: #e5e5e5;

    p {
      font-size: 17px;
      line-height: 28px;
      @media screen and (max-width: 767px) {
        font-size: .8rem;
        line-height: inherit;
      }
    }

    .six:first-child {
      padding-right: 40px;

      @media screen and (max-width: 767px) {
        padding-right: 10px;
      }
    }
  }

  .medium-padding {
    padding: 75px 0;
    @media screen and (max-width: 767px) {
      padding: 30px 0;
    }
  }

  .footer {
    margin: 0;
  }

  .field-name-field-field-bedrag-other {
    label {
      display: none !important;
    }

    .form-item {
      display: flex;
      align-items: center;

      input {
        margin: 0;
        margin-left: 10px;
      }
    }
  }

  h3 {
    margin: .5em 0;
  }

  .six.columns {
    @media screen and (max-width: 767px) {
      margin-bottom: 1em;
    }

    img {
      margin-top: 55px;
      @media screen and (max-width: 767px) {
        margin-top: 0;
      }
    }
  }
}

//donatiepagina bedankt
.section-bedanktpagina {
  .container.content {
    background: url('../images/bedanktpagina.jpg');
    min-height: 625px;
    background-position-x: 50%;
    background-position-y: center;
    background-size: cover;
    padding-top: 75px;

    h1 {
      font-size: 92px;
      line-height: 96px;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      line-height: 26px;
      font-weight: bold;
    }

    p,
    h1 {
      color: $white;
    }
  }

  .footer {
    margin: 0;
  }

  @media screen and (max-width: 500px) {
    .container.content {
      min-height: 400px;

      h1 {
        font-size: 48px;
        line-height: 56px;
      }
    }
  }
}
