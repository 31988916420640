@import 'settings';
@import 'utils/mixins';
@import 'utils/placeholders';
@import 'partials/fonts';

@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700');

html {
  -webkit-font-smoothing: antialiased;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

body {
  font-size: 100%;
  margin: 0;
  background: $white;
  font-size: 1em;
  line-height: inherit;
}

body,
caption,
div,
p,
td,
th {
  color: $body-font-color;
  font-family: $body-font-family;
  font-size: 1rem;
  line-height: inherit;
  font-weight: $body-font-weight;
  -webkit-font-smoothing: antialiased;

  @include breakpoint(small) {
    font-size: .8rem;
  }
}

strong {
  font-weight: 600;
}

hr {
  border: 0;
  border-bottom: 1px solid $gray-light;
  margin: 30px 0;
}

p {
  padding: 0 0 15px;
  margin: 0;

  img[style*='left'] {
    margin: 0 20px 20px 0;
  }

  img[style*='right'] {
    margin: 0 0 20px 20px;
  }

  .rtecenter {
    text-align: center;
  }

  &:last-child {
    padding-bottom: 0;
  }

  // @include breakpoint(medium) {
  //   font-size: 14px;
  //   line-height: 20px;
  // }
}

p.intro {
  font-size: 1.3rem;
  line-height: $base-line-height;
  font-family: $alternative-font;
  color: $secondary-color;
  letter-spacing: 0.5px;

  @include breakpoint(small) {
    font-size: 1rem;
  }
}

em {
  font-style: italic;
}

a {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: $link-color-hover;
    text-decoration: underline;
  }

  &.active {
    color: $link-color-active;
  }
}

.cke_editable ul {
  @extend %ul-caret-list;
}

// CONTENT STIJLEN
.content {
  img {
    height: auto !important;
  }

  ul:not(.contextual-links):not(.flippy):not(.slides):not(.flex-direction-nav):not(.isotope-container):not(.pager) {
    @extend %ul-caret-list;

    margin-bottom: 1em;

    p {
      display: inline;
    }
  }

  .item-list > ul li {
    list-style: none;
    margin-bottom: $column-gutter / 2;
    // padding: 0 $column-gutter / 4;
  }

  ol:not(.flex-control-nav) {
    list-style-position: outside;
    margin: 0 0 10px 18px;
    list-style: decimal;

    p {
      display: inline;
    }
  }

  table {
    width: 100% !important;

    th {
      text-align: left;
      padding: 0 0 5px 10px;
      font-weight: bold;
    }

    tbody tr td {
      padding: 5px 7px;
      border: 1px solid $gray-light;
    }
  }
}

// Headers
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  font-weight: $header-font-weight;
  line-height: $header-line-height;
  letter-spacing: 0.5px;

  &,
  & a {
    color: $header-font-color;
  }
}

// Set headers size (h1-h6)
// See _settings.scss for sizes
@for $i from 1 through length($header-size) {
  $_el: h#{$i};
  $_size: map-get($header-size, $_el);
  $_size-small: map-get($small-header-size, $_el);

  #{$_el} {
    font-size: #{$_size}px;
    @include breakpoint(small) {
      font-size: #{$_size-small}px;
    }
  }
}

.title-wrapper {
  background-color: $secondary-color;
  margin-bottom: 7em;

  @include breakpoint(medium) {
    margin-bottom: 4em;
  }

  @include breakpoint(small) {
    margin-bottom: 2em;
  }

  h1,
  h2 {
    font-size: 40px;
    color: $primary-color;
    line-height: 1.2em;
    margin: 0 !important;
    padding: 1.4em 0;

    @include breakpoint(small) {
      font-size: #{map-get($small-header-size, 'h1')}px;
    }
  }

  img {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border-radius: $icon-image-size / 2;
    height: $icon-image-size;
    width: $icon-image-size;

    @include breakpoint(small) {
      border-radius: $icon-image-size / 4;
      height: $icon-image-size / 2;
      width: $icon-image-size / 2;
    }
  }
}

// Blockquote
blockquote {
  position: relative;
  padding: 0 60px;
  width: 100%;
  float: left;
  border-color: $gray;
  margin: 10px 0;

  p {
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    font-style: italic;
    margin: 0;
    padding: 0;

    &:after,
    &:before {
      font-size: 70px;
      line-height: 70px;
      width: 60px;
      font-weight: 700;
      position: absolute;
      font-family: 'Times New Roman', Times, serif;
      font-style: italic;
    }

    &:before {
      content: '\201C';
      float: left;
      left: 0;
      top: -6px;
    }

    &:after {
      content: '\201D';
      float: right;
      right: 0;
      bottom: -32px;
    }

  }
}

#user-login .form-submit,
.btn,
.form-submit {
  font-size: .8rem;
  background: $button-color;
  border-radius: 30px;
  clear: both;
  color: $white;
  text-decoration: none;
  padding: 15px 30px;
  margin: 10px 0;
  display: inline-block;
  font-weight: bold;
  border: 0;

  @include transition(color, background-color);

  &:hover {
    color: $white;
    text-decoration: none;
    background: darken($button-color, 10%);
    cursor: pointer;
  }

  &.rand {
    background: transparent;
    border: 2px solid $button-color;
    color: $button-color;
  }

  &.btn-secondary {
    background: $secondary-color;

    &:hover {
      background: darken($secondary-color, 10%);
    }
  }

  &.btn-primary {
    background: $primary-color;

    &:hover {
      background: darken($primary-color, 10%);
    }
  }

  &.btn-agenda {
    float: right;
  }
}

.btn-back::before {
  @include extend-icon(arrow-left);
  margin-right: .5em;
}

.btn-forward::after{
  @include extend-icon(arrow-right);
  margin-left: .5em;
}

.btn-agenda::after {
  margin-left: 3em;
}

.fotoalbum-overview .thumb.col a,
.fotoalbum-sectie .thumb.col a,
a.popup {
  position: relative;
  display: block;
  line-height: 0;

  &:hover {
    opacity: .8;
  }

  img {
    position: relative;
    z-index: 0;
  }

  span.icon {
    background: url('../images/enlarge_icon_crop.png') no-repeat;
    position: absolute;
    right: 8px;
    bottom: 8px;
    display: block;
    z-index: 1;
    height: 12px;
    width: 13px;
  }

}

p.popup {
  padding: 0;
  line-height: 0;
}

// Diversen
.cke_editable,
.cke_editable_themed {
  padding: 10px !important;
}

.block-title--purple,
.infoblok h2.block-title--purple{
  color: $primary-color!important;
}

.icon-for-title{
  vertical-align: middle;
  margin-bottom: 0.75em;
  margin-right: 1.5rem;


}

.icon-for-title{
  vertical-align: middle;
  margin-bottom: 0.75em;
  margin-right: 1.5rem;


}
h3.title-after-icon{
  font-family: $body-font-family;
  font-size: (16/16)*1rem;
  position: relative;
  display: flex;
  font-weight: bold;
  min-height: 3rem;
  align-items: center;
}
