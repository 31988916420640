.banner {
  @include aspect-ratio(35, 8, 'banner-image');

  @include breakpoint(small) {
    &:before {
      padding-top: 50%;
    }
  }

  .banner-image {
    background: {
      color: $gray;
      repeat: no-repeat;
      position: center;
      size: cover;
    }
  }

  .panel-pane,
  .row,
  .columns {
    height: 100%;
  }

  h1 {
    color: $white;
    width: 40%;
    text-align: left !important;
    font-size: 43px !important;
    font-size: 3.5vw !important;
    line-height: 1.2;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include breakpoint(xlarge, 'up') {
      font-size: 2.5vw !important;
      width: 45%;
    }

    @include breakpoint(small) {
      top: auto;
      bottom: 0;
      left: -10px;
      right: -10px;
      width: auto;
      padding: 10px;
      background: $primary-color;
      transform: translateY(100%);
      font-size: .8rem !important;
    }
  }

  .profile-panel & .panel-pane {
    background: linear-gradient(to right,
      $banner-gradient-start 0%,
      $banner-gradient-end 50%,
      $banner-gradient-end 100%);
  }
}
