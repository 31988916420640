/*
	jQuery.mmenu counters addon CSS
*/

@import '../inc/variables';


em.mm-counter
{
	font: inherit;
	font-size: $mm_fontSize;
	font-style: normal;
	text-indent: 0;
	line-height: $mm_btnSize / 2;
	display: block;
	margin-top: -( $mm_btnSize / 4 );
	position: absolute;
	right: $mm_subopenWidth;
	top: 50%;

	+ a.mm-subopen
	{
		padding-left: $mm_counterWidth;

		+ a,
		+ span
		{
			margin-right: $mm_counterWidth + $mm_subopenWidth;
		}
	}
	+ a.mm-fullsubopen
	{
		padding-left: 0;
	}
}

// vertical submenu
.mm-vertical
{
	em.mm-counter
	{
		top: ( $mm_btnSize / 4 ) + 2;
		margin-top: 0;
	}
}

// Search
.mm-nosubresults > em.mm-counter
{
	display: none;
}

@include mm_colors_counters;