.profile-panel {
  h1,
  h2 {
    font-size: 46px;

    @include breakpoint(small) {
      font-size: 34px;
    }
  }

  h1,
  h2.subtitle,
  .profile-body h2 {
    margin: {
      top: 1.5em;
      bottom: .3em;
    }
    text-align: center;

    @include breakpoint(small) {
      margin-top: 2.5em;
    }
  }

  // h2:not(.subtitle) {
  .sectie-item h2 {
    font-family: $alternative-font-light;
    line-height: 1.2em;
    font-weight: normal;

    strong {
      font-family: $alternative-font;
      font-weight: normal;

      &.break {
        display: block;
      }
    }
  }
}
