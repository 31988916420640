//================================================
//=== PERSONEN ===================================
//================================================

/* OVERVIEW */
.persoon {
  margin: 0 -15px;

  .view-header {
    padding: 0 15px;

    p {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .columns {
    float: left!important;
  }

  .thumb {
    float: left;
    width: 100%;
  }

  .titel {
    float: left;
    width: 100%;
  }

  .functie {
    float: left;
    width: 100%;
  }

  .email {
    float: left;
    width: 100%;
  }

  .info {
    margin: 0;
  }

}

/* DETAIL */
.personen-detail .titel {}

.personen-detail .functie {}

.personen-detail .email {}

.personen-detail .telefoon {}