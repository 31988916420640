/*

  stash all mijnciro exceptions here.


  Header

*/
.top-menu .menu .menu-item a{
  font-size: initial;
}

.header-mijnciro{
  //border: solid 1px red;
  margin-left: 1.3em;
  // place it as first in flexbox when its on mijnciro.nl
  @include breakpoint(medium){
    display: none;
  }

  &.order-first{
    order:-1;
    margin-left: 0;
    @include breakpoint(medium){
      display: block;
    }
  }
}
  // place it as last in flexbox when its on mijnciro.nl
.logo.order-last{
  order:2;
  margin-left: 2em;
  display: block;

  @include breakpoint(medium){
    display: none;
  }
}
/*
  Mijnciro button
*/
.btn--mijnciro{
  // Button styling crude
  background-color: $secondary-color;
  font-weight: bold;
  font-size: (18/18)*1rem;
  display: inline-flex;
  align-content: flex-end;
  padding: (2/18)*1em (12/16)*1em;
  height:(34/18)*1em;
  border-radius: 8px;
  flex-grow: 0;
  text-decoration: none;

  &:hover{
    text-decoration: none;
    color: inherit;
  }
  .icon--folder{
    width:(22/18)*1em;
    margin-right: (5/18)*1em;
    margin-top: (2/18)*1em;

    background-image: url("data:image/svg+xml,%0A%3Csvg width='26px' height='22px' viewBox='0 0 26 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='MijnCiro' transform='translate(-17.000000, -7.000000)' fill='%23462846' fill-rule='nonzero'%3E%3Cg id='MijnCiro_RGB'%3E%3Cpath d='M31,9 L31,10.6 L18.3,10.6 C17.7,10.6 17.1,11.1 17.1,11.8 L17.1,26.9 C17.1,27.5 17.6,28.1 18.3,28.1 L40.9,28.1 C41.5,28.1 42.1,27.6 42.1,26.9 L42.1,25.3 L42.1,10.6 L42.1,9 C42.1,8.4 41.6,7.8 40.9,7.8 L32.2,7.8 C31.5,7.8 31,8.3 31,9 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center top 2px;

    svg{
      width:100%;
    }
  }
  .white{
    color:#fff;
  }
  color: $primary-color;
}


/*

  Menu

*/

#page.mijnciro{
  .hoofdmenu .last.leaf{
    margin-left: auto;
    float: right;
    a.menu-link{
      margin-right: 0;
      color: $secondary-color;
      &:hover{
        color: $tertiary-color;
      }
    }
  }
}


/*

  Frontpage

*/

.front #page.mijnciro .pane-node-body{
  padding-top: (68/16)*1rem;
  h1{
    font-size: (48/16)*1rem;
    margin-bottom: 0;
  }
  h2{
    font-family: $body-font-family;
    font-size: (18/16)*1rem;
    font-weight: bold;
    color: $secondary-color;
  }
}

.icon-for-title{
  vertical-align: middle;
  margin-bottom: 0.75em;
  margin-right: 1.5rem;
  max-width: 80px;
  height: auto;

}
h3.title-after-icon{
  font-family: $body-font-family;
  font-size: (16/16)*1rem;
  position: relative;
  display: flex;
  font-weight: bold;
  min-height: 3rem;
  align-items: center;
  color: $black;
}

/*

  Style banner

*/

#page.mijnciro{
  .banner .banner-image{

    position: relative;
    padding: 4rem 1rem;

  }
  .banner:before{
    padding:0;
  }
  .banner{

  }
  .banner .infowrap{
        @extend .row;
        position: relative;
        .entity{
          @extend .five;
          @extend .columns;

          height:auto;
          background-color: $white;

          padding: 1rem 2rem;


          h1,h2,h3,h4,h4,h5,h6{
            color: $primary-color !important;
          }

          p{
            color: black!important;
            font-size: 0.875rem;
          }
          .intro,
          p.intro{
            color: $teal!important;
            font-size: 1.3rem;
          }
          .btn{
            margin-top: 0;
            background-color: $secondary-color;
          }
        }
  }

}
/*
  Extra wrapper homepage
*/

.extra-wrapper{
  background-color: $gray-light;
  padding-top: 3rem;
  padding-bottom: 3rem;
  .nine .infoblok{
    padding:0rem;
    padding-right: 3rem;
    border-radius: 0;
  }
  .sidebar2{
    @extend .sidebar;
  }
  .three .infowrap{
    margin: 0 -10px;
  }
  .infowrap p{
    color:$body-font-color!important;
    &.intro{
      color: $secondary-color!important;
    }
  }
  .infowrap{
    h1,h2,h3,h4,h5,h6{
      color: $primary-color!important;
    }
  }

  .btn--mijnciro{
    font-size: 22px;
    @include breakpoint('medium'){
      font-size: 22px;
    }
    @media screen and (max-width: 886px){
      font-size: 18px;
    }

    color: $primary-color!important;


    &.white{
      color:$white!important;
    }
  }


}
