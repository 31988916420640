//================================================
////=== FOOTER =====================================
//================================================
.footer {
  margin: 200px 0 0;
  padding: 4em 0;
  background: $gray;

  @include breakpoint(medium) {
    margin-top: 100px;

    .columns {
      width: 50% !important;
    }
  }

  @include breakpoint(small) {
    margin-top: 30px;

    .columns {
      width: 100% !important;
    }

    ul,
    p:not(:last-child) {
      width: 50%;
      float: left;
    }

    .field-item {
      margin-bottom: 1em;
    }
  }

  .field-item {
    @extend %clearfix;
  }


  a,
  p {
    color: #333;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
  }

  a {
    &[href^='tel:']:before {
      @include extend-icon('phone');
      display: inline-block;
      margin-right: .3em;
      text-decoration: none;
    }
    &[href^='mailto:']:before {
      @include extend-icon('mail');
      display: inline-block;
      margin-right: .3em;
      text-decoration: none;
    }
  }

  ul {
    list-style-position: inside;
    list-style-type: none;
    margin-bottom: 1.5em;

    li {
      a {
        color: $text-color;

        &:before {
          display: inline-block;
          margin-right: .3em;
          text-decoration: none;
        }

        &.facebook:before {
          @include extend-icon('facebook');
          color: $facebook-color;
        }

        &.twitter:before {
          @include extend-icon('twitter');
          color: $twitter-color;
        }

        &.linkedin:before {
          @include extend-icon('linkedin');
          color: $linkedin-color;
        }

        &.googleplus:before {
          @include extend-icon('googleplus');
          color: $googleplus-color;
        }

        &.pinterest:before {
          @include extend-icon('pinterest');
          color: $pinterest-color;
        }

        &.youtube:before {
          @include extend-icon('youtube');
          color: $youtube-color;
        }
      }
    }
  }
}

//Footer bottom
.footer-bottom {
  padding: 5px 0 10px;

  .block {
    margin: 0;
  }

  ul {
    margin: 7px 0 0;
    list-style-position: inside;

    li {
      list-style-type: none;
      display: inline;
      margin-left: 15px;
      padding-left: 15px;
      border-left: 1px solid #73838f;
      line-height: 10px;
      display: block;
      float: left;

      &:first-child {
        border: 0;
        margin: 0;
        padding: 0;
      }

      a,
      a.active {
        color: #73838f;
        font-size: 12px;
      }

      a:hover {
        text-decoration: underline;
      }

    }
  }
}

//Home only
.front .footer {
  border: none;
}

//React logo
span.icon-rnm-react {
  font-size: 20px;
  float: left;
  margin: 0;
}

.react-online {
  color: #73838f;
  font-size: 11px;
  float: right;
}

@include breakpoint(small) {
  .footer {
    h3,
    h4 {
      padding-bottom: 0;
      margin-bottom: .5em;
    }

    .region-footerblok-1 ul li {
      width: 100%;
    }
  }

  .footer-bottom {
    .columns.left {
      float: left;
    }

    .columns.right {
      float: right;
    }

    ul {
      li {
        // float: none;
        padding: 0;
        margin: 0 0 0 20px;
        border: 0;

        a {
          line-height: 24px;
        }
      }
    }
  }

  //React logo
  .react-online-media {
    text-indent: -9999px;
    line-height: 30px;
  }

  span.icon-rnm-react {
    text-indent: 0;
  }
}

/*Standaard styling Drupal overwrite*/
.footer-bottom .menu__item.is-leaf,
.footer-bottom ul li a {
  list-style-image: none;
  list-style-type: none;
}
