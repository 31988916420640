.pagina-met-sidebar {
  .row {
    .content {
      @include breakpoint(medium, 'up') {
        padding-right: 3em;
      }
    }

    .sidebar {
      @include breakpoint(medium, 'up') {
        padding-right: $column-gutter / 2;
        margin-left: $column-gutter / -1;
      }

      @include breakpoint(medium) {
        margin-top: 2em;

        .panel-pane {
          width: 300px;
          margin: 0 auto;
        }
      }

      @include breakpoint(small) {
        .panel-pane {
          width: 100%;
        }
      }
    }

    .columns {
      @include breakpoint(medium) {
        width: 100%;
      }
    }
  }
}
