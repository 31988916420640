//================================================
//=== CASES ========================================
//================================================

.case-item {

}

.case-results {
  margin: {
    top: 150px;
    bottom: 0;
  }
  padding: 4em 0 6em;

  @include breakpoint(small) {
    margin-top: 50px;
  }

  h2,
  p {
    text-align: center;
  }

  img {
    width: $grid-card-image-size;
    height: $grid-card-image-size;
    border-radius: $grid-card-image-size / 2;
  }

  p {
    font-size: 1.2em;
    width: 150px;
    margin: 1em auto 0;
    padding: 0;
  }
}
