/*
	jQuery.mmenu header addon CSS
*/

@import '../inc/variables';


.mm-header
{
	@include mm_vendor-prefix( 'box-sizing', border-box );

	background: inherit;
	border-bottom: 1px solid transparent;
	text-align: center;
	line-height: $mm_btnSize / 2;
	width: 100%;
	height: $mm_headerHeight;
	padding: $mm_headerPaddingTop $mm_btnSize 0 $mm_btnSize;
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;

	.mm-title
	{
		@include mm_ellipsis;

		display: inline-block;
		width: 100%;
		position: relative;
		z-index: 1;
	}

	.mm-prev,
	.mm-next
	{
		@include mm_vendor-prefix( 'box-sizing', border-box );

		text-decoration: none;
		display: block;
		width: $mm_btnSize;
		height: 100%;
		position: absolute;
		bottom: 0;

		&:before
		{
			@include mm_arrow;
			margin-bottom: -( $mm_padding + 5 );
		}
	}
	.mm-prev
	{
		left: 0;

		&:before
		{
			@include mm_arrow-prev;
			left: 22px;
		}
	}
	.mm-next
	{
		right: 0;

		&:before
		{
			@include mm_arrow-next;
			right: 18px;
		}
	}
}

.mm-menu.mm-hassearch .mm-header
{
	height: $mm_headerHeight - $mm_padding;
	padding-top: $mm_headerPaddingTop - $mm_padding;
	top: $mm_searchHeight;

	.mm-prev:before,
	.mm-mext:before
	{
		margin-bottom: -( ( $mm_padding / 2 ) + 5 );
	}
}

$mm_paddingBeneathHeader: $mm_padding * 2;
.mm-menu.mm-hasheader
{
	li.mm-subtitle
	{
		display: none;
	}
	> .mm-panel
	{
		padding-top: $mm_headerHeight + $mm_paddingBeneathHeader;
		&.mm-list
		{
			padding-top: $mm_headerHeight;
		}
		> .mm-list:first-child
		{
			margin-top: -$mm_paddingBeneathHeader;
		}
	}
	&.mm-hassearch > .mm-panel
	{
		padding-top: $mm_headerHeight + $mm_searchHeight + $mm_padding;
		
		&.mm-list
		{
			padding-top: $mm_headerHeight + $mm_searchHeight - $mm_padding;
		}
	}
}



@include mm_colors_header;