.flippy {
  margin-top: 110px;
  text-align: right;

  .prev,
  .next {
    &.empty {
      // color: $dim-color !important;
      display: none;
    }

    a {
      @extend .btn;

      background: $secondary-color !important;
      &:hover {
        background: darken($secondary-color, 10%) !important;
      }
    }
  }

  .prev {
    // &.empty,
    a {
      @extend .btn-back;
      margin-top: 0 !important;
    }
  }

  .next {
    // &.empty,
    a {
      @extend .btn-forward;
      margin-top: 0 !important;
    }
  }
}

@media screen and (max-width:425px) {
  .flippy .prev a {
    font-size: .7rem;
  }
}
@media screen and (max-width:375px) {
  .flippy .prev a {
    padding: 15px 15px;
  }
}
@media screen and (max-width:320px) {
  .flippy .prev a,
  .flippy .next a {
    font-size: .8rem;
    width: 100%;
    text-align: center;
  }
}
