.top-menu {
  vertical-align: baseline;
  text-align: right;
  flex: 1;

  .block {
    display: inline-block;
    margin-left: 1.5em;

    @include breakpoint(medium) {
      display: block;
    }
  }

  // .menu .menu__item {
  .menu .menu-item {
    display: inline-block;
    margin-left: 1.5em;
    font-weight: bold;

    &.first {
      margin-left: 0;
    }

    a {
      color: $gray-dark;
    }
  }

  @include breakpoint(medium) {
    .menu,
    .zoekveld {
      display: none;
    }
  }
}

.menu-link:before {
  display: inline-block;
  margin-right: .3em;
  text-decoration: none;
}

@mixin menu-transition($properties...) {
  transition-property: $properties;
  transition-duration: .1s;
  transition-timing-function: ease;
}

.hoofdmenu {
  z-index: 1;
  position: relative;
  margin: $header-padding 0 0;
  background: $primary-color;
  padding: 10px 0;

  .menu-wrapper {
    position: static;

    .menu-link {
      z-index: 15;
    }
  }

  .menu-wrapper > ul {
    @extend %clearfix;
    margin: 0;

    > li {
      list-style: none;
      float: left;

      a {
        display: block;
        float: none;
        color: $menu-link-color;
        font-weight: bold;
        border-right: 0;
        padding: 20px 0;
        margin: 0 30px 0 0;
        font-size: 1.1em;
        text-align: center;
        white-space: nowrap;
        text-decoration: none;

        &.dimmed {
          color: $dim-color;
        }

        &.blue {
          color: $blue;

          &:hover {
            color: $menu-link-color-hover;
          }
        }

        &.active,
        &.is-active-trail {
          color: $menu-link-color-active;
        }

        &:hover {
          color: $menu-link-color-hover;
        }
      }


      //Submenu
      &.expanded {
        > .menu-link {
          position: relative;

          // Downward triangle
          &:after {
            content: '';
            visibility: hidden;
            background: $primary-color;
            border: {
              top: 0;
              right: 1px solid $primary-color;
              bottom: 1px solid $primary-color;
              left: 0;
            }
            width: 1em;
            height: 1em;
            position: absolute;
            left: 50%;
            bottom: -10px;
            transform: translateX(-50%) rotate(45deg);
            z-index: 15;

            @include menu-transition(visibility, border-color);
          }
        }

        // Horizontal line
        &:after {
          content: '';
          opacity: 0;
          visibility: hidden;
          position: absolute;
          z-index: 10;
          left: 0;
          right: 0;
          width: $row-width - $column-gutter;
          height: 1px;
          border-top: 1px solid $gray-dark;
          margin: 0 auto;

          @include menu-transition(visibility, opacity);
        }

        // Submenu pulldown
        > ul {
          @extend %clearfix;

          display: block;
          opacity: 0;
          visibility: hidden;
          position: absolute;
          left: 0;
          right: 0;
          z-index: 10;
          width: 100%;
          background: $primary-color;
          margin-top: -1px;
          // min-height: 400px;
          min-height: 200px;
          padding: 2em calc((100% - #{$row-width}) / 2) 0;
          transform: translateY(-20px);

          @include menu-transition(visibility, opacity, transform, min-height);

          > li {
            display: block;
            float: left;
            width: 100 / 3 * 1%;
            white-space: nowrap;
            border: 0;
            padding: 0 ($column-gutter * 2) ($column-gutter * 2);
            font-size: .9em;

            &:nth-child(3n+1) {
              padding-left: $column-gutter / 2;
            }

            &:nth-child(3n) {
              padding-right: $column-gutter / 2;
            }

            > a, span.nolink {
              color: $secondary-color;
            }

            span.nolink {
              font-weight: bold;
              font-size: 1.1em;
            }

            a, span.nolink {
              border-radius: 0;
              border: 0;
              padding: 0;
              width: 100%;
              text-align: left;
              display: inline-block;
              white-space: normal;
              vertical-align: top;
            }

            > ul a {
              font-weight: normal;
            }

            // Submenu links
            ul {
              @extend %ul-caret-list;

              margin-top: 1em;

              li:before {
                color: $menu-link-color !important;
              }

              li.no-list-bullet {
                margin-left: $ul-icon-size * -1;

                &:before {
                  content: '';
                }
              }

              li.separator {
                visibility: hidden;
              }

              a:before {
                margin-right: .3em;
                display: inline-block;
                text-decoration: none;
              }
            }
          }
        }
      }

      &.expanded:hover {
        > .menu-link {
          color: $menu-link-color-hover;

          // Downward triangle
          &:after {
            visibility: visible;
            border-color: $gray-dark;
          }
        }

        // Horizontal line
        &:after {
          opacity: 1;
          visibility: visible;
        }

        // Submenu pulldown
        > ul {
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
          min-height: 400px;
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $black;
  opacity: 0;
  z-index: 50;
  visibility: hidden;

  @include menu-transition(visibility, opacity);

  .menu-open & {
    opacity: .3;
    visibility: visible;
  }
}

.mobile-menu {
  display: none;
}

.mobile-menu-button {
  display: none;
  border: none;
  float: right;
  margin-left: 1em;
  // background: $primary-color;
  // color: $white;
  background: none;
  color: $primary-color;
  border-radius: 4px;
  text-align: center;
  padding: 0;
  font-size: 1rem;
  line-height: 2em;
  width: 2em;
  height: 2em;
}

.mobile-menu-button:hover {
  text-decoration: none;
  background: lighten($primary-color, 10%);
  color: $secondary-color;
}

//RESPONSIVE MOBIEL
@include breakpoint(medium) {
  .hoofdmenu {
    display: none;
  }

  //Toon mobile menu button
  .mobile-menu-button {
    display: block;
  }

  .mm-menu.mm-right {
    background: $primary-color !important;

    &,
    a {
      color: $menu-link-color !important;
      font-weight: bold;
    }

    a.active {
      color: $menu-link-color-active !important;
    }

    *,
    *:before,
    *:after {
      border-color: $gray !important;
    }

    .block.zoekveld {
      margin-bottom: 1em;
    }

    li.separator {
      visibility: hidden;
    }

    .menu-link.submenu {
      color: $dim-color !important;
    }
  }

  .mm-subclose {
    background: $tertiary-color !important;
  }

  .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    margin: 0 !important;
    border-color: #EFEFEF!important;
  }

  .mm-list a.mm-subopen:after {
    margin-bottom: -3px;
  }

  .mm-list a.mm-subclose:before {
    margin-bottom: -11px;
  }

  .mm-list {
    padding: 0 !important;
  }
  .mm-list > li > a,
  .mm-list > li > span {
    padding: 15px 20px;
  }

  .search-mobile form {
    padding: 0 20px !important;
  }

  .search-mobile input[type='text'] {
    background: rgba(0, 0, 0, 0.15);
    border: none;
    color: $white;
  }

  .search-mobile input[type='submit'] {
    position: absolute;
    top: 5px;
    right: 5px;
    font-family: $icon-font;
    background: none;
    color: $white;
    border: 0;
    font-size: 24px;
  }

  // .mm-list > li > a,
  // .mm-list > li > span {
  //   color: #333;
  // }
}

#mm-blocker {
  @include transition(top, right, bottom, left, transform, background);
  transition-duration: 0.4s;

  .mm-opening & {
    background: rgba(0, 0, 0, .5);
  }
}

#page.mijnciro{
  .hoofdmenu .last.leaf{
    margin-left: auto;
    float: right;
    a.menu-link{
      margin-right: 0;
      color: $secondary-color;
      &:hover{
        color: $tertiary-color;
      }
    }
  }
}
