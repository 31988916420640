//================================================
//=== COOKIES ====================================
//================================================
#sliding-popup.sliding-popup-bottom {
  background: rgba(0,0,0,0.55)!important;
}

#sliding-popup .popup-content {
  max-width: 1040px!important;
}

#sliding-popup .popup-content #popup-text {
  margin: 10px 0 0 !important;
}

#sliding-popup .popup-content #popup-buttons button {
  margin: 5px 0 !important;
}

#sliding-popup .popup-content #popup-text p {
  padding: 0 0 0 10px !important;
  margin: 0 !important;
}
@include breakpoint(medium) {
  #sliding-popup .popup-content #popup-text {
    float: left!important;
    width: 75%!important;
  }

  #sliding-popup .popup-content #popup-buttons {
    float: right!important;
    width: 25%!important;
  }

  #sliding-popup .popup-content #popup-buttons button {
    width: 80px!important;
  }

}
