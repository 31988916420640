//================================================
//=== VEELGESTELDE VRAGEN ========================
//================================================
.vragen-overzicht {
  .vraag {
    font-weight: bold;
  }

  .views-field-body p {
    color: #444;
  }

  .ui-accordion-content {
    height: auto!important;
    background: $white;
    border: 0;
    padding: 10px 10px 10px 15px;
  }

  .ui-accordion-header {
    background: none;
  }

  .ui-icon-triangle-1-e {
    background-position: -37px -15px;
  }

  .ui-icon-triangle-1-s {
    background-position: -68px -15px;
  }

  .ui-accordion {
    .ui-accordion-icons {
      padding-left: 15px;
    }

    .ui-accordion-header .ui-accordion-header-icon {
      top: 47%!important;
      left: 0;
    }
  }

  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default {
    border: 0;
  }
}
