//================================================
//=== SLIDER =====================================
//================================================
// .flexslider,
// .flexslider-external {
.flexslider {
  margin: 0 0 20px;
  box-shadow: none;
  background: none;
  border: 0;

  ol,
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    padding-left: 0;
    -webkit-backface-visibility: visible !important;
  }

  .row {
    @extend %row;

    position: relative;
  }

  //Caption
  .flex-caption {
    position: relative;
    z-index: 12;
  }
  .flex-caption-inner {
    position: absolute;
    border: 0;
    border-radius: $input-border-radius;
    bottom: 3em;
    right: 0;
    background: $white;
    width: 25%;
    padding: 1.3em 1.5em;
    text-decoration: none;

    @include transition(background);

    @include breakpoint(medium) {
      width: auto;
      bottom: 2em;
      background: rgba(255, 255, 255, 0.8);
      max-width: 50%;
    }
    @include breakpoint(small) {
      max-width: 100%;
    }

    .flex-caption-title {
      @extend h3;

      display: block;
      line-height: 1.3 !important;
      margin-bottom: 1em;

      @include transition(color);

      @include breakpoint(medium) {
        font-size: 14px !important;
      }
    }

    .flex-caption-link {
      @extend .btn-forward;

      display: block;
      margin-bottom: .3em;
      position: relative;
      font-weight: bold;

      @include transition(color);

      &:after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-45%);
      }
    }

    &:hover {
      background: $tertiary-color;

      .flex-caption-title,
      .flex-caption-link {
        color: $white;
      }

    }
  }

  //Bolletjes navigatie
  .flex-control-nav {
    display: none;
    z-index: 99;

    @include breakpoint(medium) {
      display: block;
      bottom: 0;
    }

    li {
      margin: 0 4px;

      a {
        width: 9px;
        height: 9px;
        background: $dim-color;

        &.flex-active {
          background: $secondary-color;
        }
      }
    }

  }

  // Pijltjes navigatie
  .flex-direction-nav {
    @include breakpoint(medium) {
      display: none;
    }

    a {
      color: $white;
      top: 0;
      height: 100%;
      margin: 0;
      width: 60px;

      &:before {
        top: 50%;
        position: absolute;
        margin-top: -32px;
      }
    }

    .flex-next {
      right: $column-gutter;

      &:before {
        right: $column-gutter;
      }
    }

    .flex-prev {
      left: $column-gutter;

      &:before {
        left: $column-gutter;
      }
    }
  }

  .bg-caption,
  .color-caption {
    display: none;
  }
}

//Tablet staand
@include breakpoint(large) {
  .flexslider {
    .flex-caption {
      font-size: 20px;
      line-height: 20px;
      padding: 15px;
    }
  }
}

//Telefoon
@include breakpoint(medium) {
  .flexslider {
    .flex-caption {
      width: 100%;
      left: auto;
      bottom: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      padding: 15px;
      right: auto!important;
      left: auto!important;
      border-radius: 0!important;
    }

    .slides img {
      width: 100%;
      max-width: none;
    }

    .flex-direction-nav a:before {
      top: 30%;
    }
  }
}


/*
  Remove margins in home slider.
*/
.front.domain-cirohorn{
  .flexslider{
    margin-bottom: 0;
  }
  .flex-caption{
    padding: 0;
  }
  .flex-caption-inner{
    left:0;
    right:unset;
    bottom: 6em;
    width:50%;
      @include breakpoint(medium){
        width:100%;
        bottom: 3em;
      }
  }

  .image-mobile{
    display: none;
  }
  .flexslider .slides img + img{
    display: none;
  }
  @include breakpoint(small) {
    .flexslider .slides img{
        display: none;
    }
    .flexslider .slides img + img{
      display: block;
    }
  }
}
