/**
 * @file
 * Print styling
 *
 * We provide some sane print styling for Drupal using Zen's layout method.
 */

/**
 * By importing this CSS file as media 'all', we allow this print file to be
 * aggregated with other stylesheets, for improved front-end performance.
 */
@media print {
  a {
    /* Underline all links. */
    &:link,
    &:visited {
      text-decoration: underline !important;
    }

    /* Don't underline header. */
    &:link.header__site-link,
    &:visited.header__site-link {
      text-decoration: none !important;
    }
  }
  /* Add visible URL after links. */
  #content {
    a {
      &[href]:after {
        content: ' (' attr(href) ')';
        font-weight: normal;
        font-size: 16px;
      }

      /* Only display useful links. */
      &[href^='#']:after,
      &[href^='javascript:']:after {
        content: '';
      }
    }

    /* Add visible title after abbreviations. */
    abbr[title]:after {
      content: ' (' attr(title) ')';
    }

    /* Un-float the content. */
    float: none !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    color: #000;
    background-color: transparent !important;
    background-image: none !important;
  }
  /* Turn off any background colors or images. */
  #main,
  #page,
  body {
    color: #000;
    background-color: transparent !important;
    background-image: none !important;
  }
  /* Hide sidebars and nav elements. */
  #footer,
  #navigation,
  #skip-link,
  #toolbar,
  .action-links,
  .book-navigation,
  .breadcrumb,
  .feed-icons,
  .forum-topic-navigation,
  .links,
  .pager,
  .region-sidebar-first,
  .region-sidebar-second,
  .tabs {
    visibility: hidden;
    display: none;
  }
}
