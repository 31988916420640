//================================================
//=== PRODUCTEN ==================================
//================================================
/*Product overview*/
.product-overview .row {
  margin-bottom: 50px;
}

.product-overview [class*='column']+[class*='column']:last-child {
  float: left;
}

.product-item {
  .col {
    float: left;
    width: 100%;
  }

  .thumb img {
    width: 100%;
  }

  .lees-meer {
    border-top: 1px solid #f1f1f1;
    padding-top: 5px;
    margin-top: 5px;

    a {
      float: right;
    }
  }
}

/*Product detail*/
.product-detail {
  margin: 0 -15px;

  .col {
    width: 50%;
    float: left;
    padding: 0 15px;
  }

  .thumb img {
    width: 100%;
  }

  .prijs {
    font-size: 30px;
    line-height: 40px;
    border-top: 1px solid #f1f1f1;
    padding-top: 7px;
    margin-top: 10px;
    text-align: right;
  }
}
