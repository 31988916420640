%card-text-white {
  h1,
  h2,
  h3,
  h4,
  p,
  a,
  span,
  .card-link,
  .card-link:before,
  a:before,
  li:before {
    color: $white !important;
  }
}

@mixin card-hover($bg-color, $color-fn: 'darken') {
  @extend %card-text-white;
  background-color: $bg-color;

  &:not(.card-no-link):hover {
    @if $color-fn == darken {
      background-color: darken($bg-color, 10%);
    }
    @if $color-fn == lighten {
      background-color: lighten($bg-color, 10%);
    }
  }
}

.card {
  background-color: $gray;
  border-radius: $input-border-radius;
  border: 0;
  overflow: hidden;
  padding-bottom: 3em;
  margin-bottom: $column-gutter;
  display: block;
  color: $text-color;
  position: relative;
  transform: translate3d(0, 0, 0);

  @include transition(background-color);

  &.primary-color {
    @include card-hover($primary-color, 'lighten');
  }

  &.secondary-color {
    @include card-hover($secondary-color);
  }

  &.tertiary-color {
    @include card-hover($tertiary-color);
  }

  &.card-no-link {
    padding-bottom: .5em;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    padding: 0;
  }

  ul {
    margin: 1em !important;

    li {
      margin: 0 !important;
      white-space: nowrap;

      a {
        white-space: normal;
      }
    }
  }

  .card-thumbnail {
    width: 100%;
    height: 200px;
    display: block;
    position: relative;
    overflow: hidden;

    &.no-img {
      height: 0;
    }

    .card-thumbnail-image {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      display: block;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transform: translate3d(0, 0, -1) scale(1, 1);
      transition: transform 3s linear;
    }
  }

  .card-date,
  .card-title,
  .card-link {
    color: $primary-color;
    margin: 1em;
    display: block;
    line-height: 1.3;
  }
  @media screen and (max-width: 768px) {
    .card-title {
      font-size: 20px;
      word-break: break-word;
    }
  }

  .card-link {
    position: absolute;
    bottom: .5em;

    &:before {
      @include extend-icon(caret-right);
      color: $secondary-color;
    }

    &.right {
      right: 1em;
      color: $tertiary-color;

      &:before {
        content: '';
      }

      &:after {
        @include extend-icon(arrow-right);
        margin-left: .5em;
      }
    }

    a {
      color: inherit;
    }
  }

  &:not(.card-no-link):hover {
    text-decoration: none;
    background-color: darken($gray, 10%);

    .card-thumbnail-image {
      transform: scale(1.2, 1.2);
    }
  }
}

.card-grid .row {
  &:nth-child(1) .columns {
    &:nth-child(2) .card {
      @extend .card.primary-color;
    }
  }

  &:nth-child(2) .columns {
    &:nth-child(1) .card {
      @extend .card.tertiary-color;
    }

    &:nth-child(3) .card {
      @extend .card.secondary-color;
    }
  }
}

.sidebar {
  margin: 0 ($column-gutter / -2);
  clear: both;

  @include breakpoint(small) {
    margin: 2em 0 0;
  }

  .card,
  .infowrap {
    margin-bottom: 2em;

    .card-thumbnail {
      height: 160px;
    }
  }

  .pane-node-field-testimonial-afbeelding, .pane-node-field-testimonial-link {
    background-color: $gray;
    border: 0;
    overflow: hidden;
    display: block;
    color: $text-color;
    position: relative;
    transform: translate3d(0, 0, 0);

    .content {
      padding: 0!important;
    }
  }

  .pane-node-field-testimonial-afbeelding {
    border-top-left-radius: $input-border-radius;
    border-top-right-radius: $input-border-radius;
    margin: 1em 1.5em 0;
  }

  .pane-node-field-testimonial-link {
    border-radius: $input-border-radius;
    margin: 0 1.5em 1.5em;
    position: relative;
    a {
      padding: 1em 1em 3.5em 1em;
      display: block;
      font-size: 18px;
      color: #462846;
      line-height: 1.3;
      font-family: $alternative-font;
    }
    .testimonial-link {
      //display: block;
      position: absolute;
      font-size: 16px;
      padding: 0 1em 1.5em 1em !important;
      bottom: 0;
      z-index: -1;
      &:before {
        @include extend-icon(caret-right);
        color: $secondary-color;
      }
    }
  }

  .pane-node-field-testimonial-afbeelding + .pane-node-field-testimonial-link {
    border-radius: 0 0 $input-border-radius $input-border-radius;
  }
}
