//	Animations
$mm_transitionDuration: 0.4s !default;
$mm_transitionFunction: ease !default;

//	Sizes
$mm_width: 0.8 !default;
$mm_minWidth: 140px !default;
$mm_maxWidth: 440px !default;

$mm_height: 0.8 !default;
$mm_minHeight: 140px !default;
$mm_maxHeight: 880px !default;

$mm_btnSize: 40px !default;
$mm_padding: 10px !default;
$mm_fontSize: 14px !default;

//	Addon sizes
$mm_subpanelOffset: 40% !default;
$mm_subopenWidth: $mm_btnSize !default;
$mm_counterWidth: $mm_btnSize !default;
$mm_toggleHeight: $mm_btnSize - $mm_padding !default;
$mm_toggleWidth: ( $mm_toggleHeight * 2 ) - $mm_padding !default;
$mm_headerHeight: $mm_btnSize * 1.5 !default;
$mm_headerPaddingTop: $mm_headerHeight / 2 !default;
$mm_searchHeight: $mm_btnSize + $mm_padding !default;
$mm_searchfieldHeight: $mm_searchHeight - ( $mm_padding * 2 ) !default;

//	Colors
$mm_baseBg: #333 !default;
$mm_pageShadow: 0 0 20px rgba( 0, 0, 0, 0.5 ) !default;
$mm_color: rgba( 255, 255, 255, 0.6 ) !default;
$mm_dimmedColor: rgba( 255, 255, 255, 0.3 ) !default;
$mm_emphasizedBg: rgba( 0, 0, 0, 0.1 ) !default;
$mm_highlightedBg: rgba( 255, 255, 255, 0.05 ) !default;
$mm_borderColor: rgba( 0, 0, 0, 0.15 ) !default;

//	Addon colors
$mm_toggleCheckedColor: #4bd963 !default;



@import 'sizing';
@import 'colors';
@import 'arrows';
@import 'borders';


//	Misc mixins
@mixin mm_vendor-prefix( $prop, $val )
{
	-webkit-#{$prop}: $val;
	-moz-#{$prop}: $val;
	-ms-#{$prop}: $val;
	-o-#{$prop}: $val;
	#{$prop}: $val;
}
@mixin mm_ellipsis
{
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
