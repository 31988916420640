//================================================
//=== SEARCH RESULTS =============================
//================================================
.search-results {
  margin: 2em 0 0 0 !important;
  border-top: 1px solid $gray;
}

.search-info {
  display: none;
}

.search-result {
  padding: 3em 0;
  border-bottom: 1px solid $gray;
  margin-bottom: 0 !important;

  strong {
    color: $tertiary-color;
  }
}

.search-results .search-snippet-info {
  padding-left: 0;
}

.search-api-page-results {
  float: left;
  width: 100%;
}
