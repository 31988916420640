// Grid
$row-width: 1024px !default;
$column-gutter: 20px !default;
$total-columns: 12 !default;
$mobile-total-columns: 4 !default;
$paragraphs-gutter: 30px !default;

$header-padding: 32px;


// Colors
$white: #fff !default;
$black: #000 !default;

$green: #55a514 !default;
$red: #c60f13 !default;

$teal: #00b5c3 !default;
$aubergine: #462846 !default;
$blue: #4158c3 !default;

$gray: #e5e5e5 !default;
$gray-medium: #cbcbcb !default;
$gray-dark: #818181 !default;
$gray-light: #f0f0f0 !default;

$text-color: #303030 !default;
$primary-color: $aubergine !default;
$secondary-color: $teal !default;
$tertiary-color: $blue !default;
$dim-color: #a395a0 !default;

$link-color: $blue !default;
$link-color-hover: $link-color !default;
$link-color-active: $link-color !default;
$menu-link-color: $white !default;
$menu-link-color-hover: $teal !default;
$menu-link-color-active: $menu-link-color-hover !default;
$button-color: $blue !default;

$alert-color: $red !default;
$warning-color: #f08a24 !default;
$success-color: $green !default;

$facebook-color: #3b5998 !default;
$twitter-color: #55acee !default;
$linkedin-color: #0077b5 !default;
$googleplus-color: #dd4b39 !default;
$pinterest-color: #cc2127 !default;
$youtube-color: #cd201f !default;

// Typography
$base-font-size: 18px;
$base-line-height: 1.5;
$header-line-height: 1.3;
$ul-icon-size: 1.3em;

$header-size: (
  h1: 38,
  h2: 22,
  h3: 20,
  h4: 18,
  h5: 16,
  h6: 14,
);
$small-header-size: (
  h1: 28,
  h2: 20,
  h3: 18,
  h4: 16,
  h5: 14,
  h6: 12,
);

$icon-font: 'ciro-horn';
$default-font: 'Noto Sans', sans-serif !default;
$alternative-font: 'TypewriterSerial-Xbold', Courier, monospace !default;
$alternative-font-light: 'TypewriterSerial Regular', Courier, monospace !default;

$header-font-family: $alternative-font !default;
$header-font-weight: normal !default;
$header-font-style: normal !default;
$header-font-color: $primary-color !default;

$body-font-family: $default-font !default;
$body-font-weight: normal !default;
$body-font-style: normal !default;
$body-font-color: $text-color !default;


// Media Queries
//  use media queries with the mixin `breakpoint(small)` or other size
// Tablet Portrait = own MQ:
// $screen-small: 424px !default;
// $screen-medium: 767px !default;
// $screen-large: 1023px !default;
// $breakpoints: (
//   small:  $screen-small,
//   medium: $screen-medium,
//   large:  $screen-large,
// ) !default;

// Tablet Portrait = Mobile:
$screen-small:  767px !default;
$screen-medium: 1023px !default;
$screen-large:  1200px !default;
$screen-xlarge: 1600px !default;
$breakpoints: (
  small:  $screen-small,
  medium: $screen-medium,
  large:  $screen-large,
  xlarge: $screen-xlarge,
) !default;

$breakpoint-classes: (small medium large xlarge) !default;


// Text Direction
$text-direction: ltr !default; // Controls default global text direction, 'rtl' or 'ltr'
$default-float: left !default;
$default-opposite: right !default;
@if $text-direction != ltr {
  $default-float: right;
  $default-opposite: left;
}


// Forms
$form-spacing: 20px !default;
$label-font-weight: 500 !default;
$label-font-color: lighten($black, 30%) !default;
$label-bottom-margin: 3px !default;
$input-font-color: $gray-dark !default;
$input-font-weight: bold !default;
// $input-font-size: floor($base-font-size * .8) !default;
$input-font-size: .8rem !default;
$input-bg-color: #fff !default;
$input-focus-bg-color: darken($white, 2%) !default;
$input-border-color: $gray-dark !default;
$input-focus-border-color: lighten($gray-dark, 20%) !default;
$input-border-style: solid !default;
$input-border-width: 1px !default;
$input-border-radius: 30px !default;
$input-padding: 15px 20px !default;
$fieldset-border-radius: 3px !default;
$select-caret-width: 50px !default;
$select-caret-color: $secondary-color !default;
$select-caret-border-width: 0 !default;


// Transitions
$transition-duration: .3s !default;
$transition-easing: ease !default;

$grid-card-image-size: 120px;
$icon-image-size: 100px;
$banner-gradient-start: rgba(0, 0, 0, .4);
$banner-gradient-end: rgba(0, 0, 0, 0);

$link-submenu-anchor: '#submenu';
