.vacature-detail,
.nieuws-detail,
.publication-detail {
  section.content {
    .pane-node-field-datum,
    .pane-node-field-auteurs {
      display: block;
      margin-bottom: 1em;
    }

    h1 {
      font-size: 32px;
      margin-bottom: 2rem;

      @include breakpoint(small) {
        font-size: 24px;
        margin-bottom: 1rem;
      }
    }

    .field-type-image img {
      margin: 2em 0;
      width: 100%;
    }

    h2 {
      margin: .5em 0 0;
    }

    p {
      padding-bottom: 1.8em;
    }

    table {
      margin: 2em 0;

      td {
        padding: .8em 1em;

        p {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}

.publication-detail .content {
  .pane-node-field-datum {
    margin-bottom: 0;
  }
}
