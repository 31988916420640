//================================================
//=== WEBFORM ====================================
//================================================
.webform-progressbar {

  margin-left: 17px;
  margin-bottom: 20px;
  margin-top: 30px;
  display: inline-block;

  .webform-progressbar-inner {
    border-color: #2b2b2b;
    background-color: #2b2b2b;
  }

  .webform-progressbar-page {
    border: 1px solid #2b2b2b;
  }

  .webform-progressbar-page.current {
    background-color: #2b2b2b;
  }

  .webform-progressbar-page.completed {
    background-color: #2b2b2b;
  }

  .webform-progressbar-outer {
    border: 1px solid #2b2b2b;
  }
}

.webform-client-form {
  @extend %clearfix;

  input[type=date],
  input[type=datetime],
  input[type=email],
  input[type=number],
  input[type=password],
  input[type=search],
  input[type=tel],
  input[type=text],
  input[type=time],
  input[type=url],
  textarea {
    margin: 0;
  }

  .form-item {
    margin: 0 0 20px;
    // float: left; width: 100%;

    .form-item {
      margin: 0;
    }

    label {
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  fieldset .form-item {
    padding: 0;
  }

  &.preview .webform-page {
    border-bottom: 1px solid #efefef;
    padding-bottom: 40px;
    text-indent: -9999px;
    display: none;
  }

  // Type: radio
  .webform-component-radios {
    &.inline {
      .form-type-radio {
        float: left;
        width: auto;
        margin: 0 15px 0 0;
      }
    }

    .form-type-radio label {
      font-weight: normal;
    }
  }

  // Type: checkbox
  .webform-component-checkboxes {
    .form-type-checkbox label {
      font-weight: normal;
    }
  }

  // Type: file
  .webform-component-file {
    .description {
      float: left;
      width: 100%;
      margin: 10px 0 0;
      color: #707A80;
    }

    input[type='submit'] {
      float: left;
      width: 106px;
      clear: both;
      background: #707A80;
      margin: 10px 0 0;
    }
  }

  // Layout: Layout Box
  .webform-layout-box {
    margin: 0 -15px;
    clear: both;
    //float:left;

    > .form-item {
      padding: 0 15px;
      @include breakpoint(medium) {
        width: auto;
        float: none;
      }
    }
  }

  // Inline velden
  .webform-container-inline {
    > label {
      margin: 0;
      width: 40%;
      float: left;
      padding-right: 20px;
    }

    &.webform-component-textfield {
      input {
        width: 60%;
        float: right;
      }
    }

    > .form-checkboxes,
    > .form-textarea-wrapper,
    > input,
    > .form-radios {
      // width: 60%;
      float: right;
    }

    div.description {
      display: block;
      float: left;
      width: 100%;
    }

    &.beschrijving-links div.description {
      width: 40%;
      line-height: 20px;
    }
  }

  // Veldengroep
  .webform-component-fieldset {
    width: 50%;
    float: left;
    padding: 0 ($column-gutter/2);
    border: none;
    margin: 0;

    //&:nth-child(even) { padding-right: 0; }
    //&:nth-child(odd) { padding-left: 0; }

    .fieldset-legend {
      display: none;
    }

    // Links en Rechts
    &.links,
    &.webform-component--kolom-verdeling-1,
    &.webform-component--links,
    &.webform-component--links1 {
      padding-left: 0 !important;
    }

    &.rechts,
    &.webform-component--kolom-verdeling,
    &.webform-component--rechts,
    &.webform-component--rechts1 {
      padding-right: 0 !important;
    }
    @include breakpoint(medium) {
      width: auto;
      float: none;
    }
    @include breakpoint(small) {
      padding: 0 !important;
    }
  }

  // Submit
  .btn,
  .form-submit {
    // float: right;
    float: left;
    margin: 15px 0 0;
    -webkit-appearance: none;
    clear: none;

    &.webform-next {
      float: right;
      clear: none;
    }

    &.webform-previous {
      float: left;
      clear: none;
      background: #E9EAED;
    }
  }

  //Voltooid bericht
  .webform-confirmation {
    padding: 0 15px;
  }

  //AJAX loader
  .ajax-progress {
    margin-left: 15px;
  }

  .throbber {
    background: url('../images/ajax-loader.gif');
    position: absolute;
    bottom: 0;
    height: 11px;
    width: 16px;
    margin: 2px;
    z-index: 10;
  }

  // Error
  .error input,
  .error input:focus,
  .error textarea,
  .error textarea:focus,
  .error:focus,
  input.error,
  input.error:focus,
  textarea.error {
    background: $white!important;
    border-color: #db0f48!important;
  }
}

.webform-component-date .webform-container-inline {
  @extend %clearfix;

  margin-left: $column-gutter / -2 !important;
  margin-right: $column-gutter / -2 !important;

  .form-item {
    display: block !important;
    width: 100 / 3 * 1%;
    float: left;
    padding: 0 ($column-gutter / 2);
  }

  .select {
    display: block;
  }
}

%inline-choice {
  display: inline-block;
  vertical-align: top;
}

.form-type-radio,
.form-type-checkbox {
  white-space: nowrap;

  label {
    white-space: normal;
    word-wrap: break-word;
    max-width: calc(100% - 20px);
  }

  input {
    margin-top: .5em;
  }
}

.form-type-checkbox {
  input[type='checkbox'],
  label {
    @extend %inline-choice;
  }
}

.form-type-radio {
  input[type='radio'],
  label {
    @extend %inline-choice;
  }

  .salutation & {
    display: inline-block;
    margin-right: 1em !important;
  }
}

.form-required {
  color: $red;
}

.block-webform .links,
.webform-client-form .links {
  float: left;
  padding: 0 15px;
}

.node-type-webform .webform-confirmation {
  padding: 0 !important;
}

.webform-client-form-44 {
  margin: 0;

  .webform-component-markup {
    h2 {
      margin: 30px 0 20px;
    }
  }

  .form-item {
    @include breakpoint(medium) {
      padding: 0;
    }
  }
}

.contactform {
  .bg-fill {
    text-align: center;
  }

  .form-textarea-wrapper {
    padding-bottom: $form-spacing;

    textarea {
      height: 100%;
    }
  }
}

.contact-blocks {
  @extend %row;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-size: 1.2em;
  padding: 0 10px;
  @include breakpoint(small) {
    flex-direction: column;
    align-items: stretch;
    width: auto !important;
    display: inline-block;
  }

  .contact-block {
    // flex: 1 1 0;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include breakpoint(small) {
      margin-bottom: 1em;
    }

    [class*=' icon-'],
    [class^='icon-'] {
      float: left;
      color: $secondary-color;
      background-color: $primary-color;
      font-size: 3em;
      line-height: 1.45;
      display: block;
      height: 1.5em;
      width: 1.5em;
      border-radius: 0.75em;
      text-align: center;
      margin-right: 0.3em;
      @include breakpoint(small) {
        font-size: 2em;
      }
    }

    p {
      margin: 0;
      padding: 0;
      text-align: left;
    }

    a {
      color: inherit;
    }
  }
}

.aanvraag-boekje {
  h2 {
    font-size: 34px;
    margin-bottom: 1em;
  }

  form {
    margin-top: 4em;
    width: 75%;
  }

  .pane-node-body p {
    padding: 0;
  }
}

.webform-confirmation {
  h1,
  h2 {
    margin-top: 1em;
  }

  + .links {
    width: $row-width;
    margin: 50px auto;
    padding: 0 ($column-gutter / 2);
  }
}
