/*
	jQuery.mmenu dragOpen addon CSS
*/

@import '../inc/variables';

html.mm-opened.mm-dragging
{
	.mm-menu,
	.mm-page,
	.mm-fixed-top,
	.mm-fixed-bottom,
	#mm-blocker
	{
		@include mm_vendor-prefix( 'transition-duration', 0s );
	}
}