//================================================
//=== TWEETS =====================================
//================================================
.tweets .tweets-item {
  float: left;
  width: 100%;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;

  .datum {
    color: #999;
    font-size: 11px;
    display: block;
  }

  .content a {
    text-decoration: underline;
  }

}
