//================================================
//=== BREADCRUMB =================================
//================================================

.breadcrumbs a,
.breadcrumbs span {
  font-size: 14px;
  color: $gray;
}

.easy-breadcrumb_segment-separator {
  padding: 0;
}

// Hide op homepage
.front .breadcrumbs {
  display: none;
}

.breadcrumbs {
  @extend %row;

  margin: ($column-gutter / 2) auto $column-gutter;

  .easy-breadcrumb {
    padding: 0 ($column-gutter / 2);
  }
}
