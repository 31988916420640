.slider-wrapper {
  position: relative;
  margin-bottom: 120px;
  overflow: hidden;

  @include breakpoint(medium) {
    margin-bottom: 80px;
  }

  @include breakpoint(medium) {
    margin-bottom: 20px;
  }
}

.homeblock-treatments {
  @media screen and(min-width: 768px) {
    position: absolute;
    top: 3em;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    height: 0; // Prevents overlapping flexslider caption
  }

  .treatments {
    position: relative;
    z-index: 10;

    .view-header,
    .view-content {
      background: $secondary-color;
    }

    .view-header {
      padding: 30px;
      border-top-left-radius: $input-border-radius;
      border-top-right-radius: $input-border-radius;

      h2 {
        font-size: 28px;
        line-height: 1.3;
      }

      @include breakpoint(medium) {
        padding: 20px;

        h2 {
          font-size: 18px;
        }
      }
    }

    .view-content {
      padding: 0 30px 20px;
      border-bottom-left-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
      margin-top: -1px;

      @include breakpoint(medium) {
        padding: 0 20px 10px;
      }
    }

    ul {
      @extend %clearfix;

      li {
        line-height: 1;
        padding-bottom: .8em !important;
        display: block;
        float: left;
        width: 50%;
        font-weight: bold;
        white-space: nowrap;

        &:before,
        a {
          color: $white !important;
          white-space: normal;
          vertical-align: top;
          line-height: 1.2;
        }
      }
    }
  }
}

.front {
  section.content {
    padding-right: 3em;
  }

  h1 {
    margin-bottom: .5em;
    font-size: 34px;
  }

  .btn {
    margin-top: 3em;
  }

  @include breakpoint(small) {
    section.content {
      padding-right: 0;
    }

    .btn {
      margin-top: 2em;
    }
  }

  .main-wrapper {
    margin: 0 0 4em;
  }

  .full-width-wrapper {
    background: $primary-color;
    padding: 4em 0;
    // margin: 4em 0 0;

    .view-header h2 {
      font-size: 32px;
      color: $white;
      margin-bottom: 1.5em;

      @include breakpoint(medium) {
        font-size: 22px;
        margin-bottom: 1em;
      }
    }

    .pane-homeblokken .columns {
      @media screen and (min-width: $screen-small) and (max-width: $screen-medium) {
        width: 50%;

        &:nth-child(3) {
          display: none;
        }
      }
    }

    .card {
      background-color: $white;

      &:hover {
        background-color: $gray;
      }
    }
  }

  .footer {
    margin: 0;
  }
}
