.paragraphs-item-afbeeldingen {
  .field-item {
    img {
      display: block;
    }
  }
}

.panel-pane > .paragraphs-items {
  @extend %row;
}

.entity-paragraphs-item {
  @extend %clearfix;
  margin: 0 (($paragraphs-gutter * -1) - ($column-gutter / 2));
  padding: 50px ($column-gutter / 2) 0;

  @include breakpoint(small) {
    padding-top: 20px;
  }

  .paragraphs-items {
    width: 100%;
    float: left;
    padding: 0 $paragraphs-gutter;
  }
}

.paragraphs-item-twee-kolommen {
  .paragraphs-items-field-paragraaf-kolom-1,
  .paragraphs-items-field-paragraaf-kolom-2 {
    width: 50%;

    @include breakpoint(small) {
      width: 100%;
    }

    .entity-paragraphs-item {
      margin: 0;
    }
  }
}

.paragraphs-item-view-kolommen {
  .paragraphs-items-field-paragraaf-kolom-1,
  .paragraphs-items-field-paragraaf-kolom-2,
  .paragraphs-items-field-paragraaf-kolom-3,
  .paragraphs-items-field-paragraaf-kolom-4 {
    width: 25%;

    @include breakpoint(small) {
      width: 100%;
    }

    .entity-paragraphs-item {
      margin: 0;
    }
  }
}
