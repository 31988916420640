/*
	jQuery.mmenu effects extension CSS
*/

@import '../inc/variables';


//	Slide
html.mm-slide
{
	.mm-menu
	{
		-webkit-transition: -webkit-transform $mm_transitionDuration $mm_transitionFunction;
		-moz-transition: -moz-transform $mm_transitionDuration $mm_transitionFunction;
		-o-transition: -o-transform $mm_transitionDuration $mm_transitionFunction;
		transition: transform $mm_transitionDuration $mm_transitionFunction;
	}

	//	Left
	&.mm-opened .mm-menu
	{
		@include mm_vendor-prefix( 'transform', translateX( -$mm_subpanelOffset ) );
	}
	&.mm-opening .mm-menu
	{
		@include mm_vendor-prefix( 'transform', translateX( 0% ) );
	}

	//	Right
	&.mm-right
	{
		&.mm-opened .mm-menu
		{
			@include mm_vendor-prefix( 'transform', translateX( $mm_subpanelOffset ) );
		}
		&.mm-opening .mm-menu
		{
			@include mm_vendor-prefix( 'transform', translateX( 0% ) );
		}
	}

	//	Top
	&.mm-top
	{
		&.mm-opened .mm-menu
		{
			@include mm_vendor-prefix( 'transform', translateY( -$mm_subpanelOffset ) );
		}
		&.mm-opening .mm-menu
		{
			@include mm_vendor-prefix( 'transform', translateY( 0% ) );
		}
	}

	//	Bottom
	&.mm-bottom
	{
		&.mm-opened .mm-menu
		{
			@include mm_vendor-prefix( 'transform', translateY( $mm_subpanelOffset ) );
		}
		&.mm-opening .mm-menu
		{
			@include mm_vendor-prefix( 'transform', translateY( 0% ) );
		}
	}
}


//	Zoom menu
$scaleDown: 0.7;
$scaleUp: 1.5;
html.mm-zoom-menu
{
	.mm-menu
	{
		-webkit-transition: -webkit-transform $mm_transitionDuration $mm_transitionFunction;
		-moz-transition: -moz-transform $mm_transitionDuration $mm_transitionFunction;
		-o-transition: -o-transform $mm_transitionDuration $mm_transitionFunction;
		transition: transform $mm_transitionDuration $mm_transitionFunction;
	}

	//	Left
	&.mm-opened .mm-menu
	{
		@include mm_vendor-prefix( 'transform', scale( $scaleDown, $scaleDown ) translateX( -$mm_subpanelOffset ) );
		@include mm_vendor-prefix( 'transform-origin', left center );
	}
	&.mm-opening .mm-menu
	{
		@include mm_vendor-prefix( 'transform', scale( 1, 1 ) translateX( 0% ) );
	}

	//	Right
	&.mm-right
	{
		&.mm-opened .mm-menu
		{
			@include mm_vendor-prefix( 'transform', scale( $scaleDown, $scaleDown) translateX( $mm_subpanelOffset ) );
			@include mm_vendor-prefix( 'transform-origin', right center );
		}
		&.mm-opening .mm-menu
		{
			@include mm_vendor-prefix( 'transform', scale( 1, 1 ) translateX( 0% ) );
		}
	}

	//	Top
	&.mm-top
	{
		&.mm-opened .mm-menu
		{
			@include mm_vendor-prefix( 'transform', scale( $scaleDown, $scaleDown ) translateY( -$mm_subpanelOffset ) );
			@include mm_vendor-prefix( 'transform-origin', center top );
		}
		&.mm-opening .mm-menu
		{
			@include mm_vendor-prefix( 'transform', scale( 1, 1 ) translateY( 0% ) );
		}
	}

	//	Bottom
	&.mm-bottom
	{
		&.mm-opened .mm-menu
		{
			@include mm_vendor-prefix( 'transform', scale( $scaleDown, $scaleDown ) translateY( $mm_subpanelOffset ) );
			@include mm_vendor-prefix( 'transform-origin', center bottom );
		}
		&.mm-opening .mm-menu
		{
			@include mm_vendor-prefix( 'transform', scale( 1, 1 ) translateY( 0% ) );
		}
	}
}


//	Zoom page
html.mm-zoom-page
{

	.mm-page
	{
		-webkit-transition-property: -webkit-transform, top, right, bottom, left, border;
		-moz-transition-property: -moz-transform, top, right, bottom, left, border;
		-ms-transition-property: -ms-transform, top, right, bottom, left, border;
		-o-transition-property: -o-transform, top, right, bottom, left, border;
		transition-property: transform, top, right, bottom, left, border;
	}

	//	Left
	&.mm-opened .mm-page
	{
		@include mm_vendor-prefix( 'transform', scale( 1, 1 ) );
		@include mm_vendor-prefix( 'transform-origin', left center );
	}
	&.mm-opening .mm-page
	{
		@include mm_vendor-prefix( 'transform', scale( $scaleUp, $scaleUp ) );
	}

	//	Right
	&.mm-right.mm-opened .mm-page
	{
		@include mm_vendor-prefix( 'transform-origin', right center );
	}

	//	Top
	&.mm-top.mm-opened .mm-page
	{
		@include mm_vendor-prefix( 'transform-origin', center top );
	}

	//	Bottom
	&.mm-bottom.mm-opened .mm-page
	{
		@include mm_vendor-prefix( 'transform-origin', center bottom );
	}
}


//	Zoom panels
html.mm-zoom-panels .mm-menu.mm-horizontal > .mm-panel
{
	@include mm_vendor-prefix( 'transform', scale( $scaleUp, $scaleUp ) );
	@include mm_vendor-prefix( 'transform-origin', left center );

	-webkit-transition-property: -webkit-transform, left;
	-moz-transition-property: -moz-transform, left;
	-ms-transition-property: -ms-transform, left;
	-o-transition-property: -o-transform, left;
	transition-property: transform, left;
	
	&.mm-opened
	{
		@include mm_vendor-prefix( 'transform', scale( 1, 1 ) );
		
		&.mm-subopened
		{
			@include mm_vendor-prefix( 'transform', scale( $scaleDown, $scaleDown ) );
		}
	}
}
