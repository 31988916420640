.content {
  @extend %clearfix;
}

//========================================
// Overzichten
//========================================
.overzicht {
  &:hover {
    a {
      opacity: .8;
    }
  }

  a {
    display: block;
    margin-bottom: 30px;

    img {
      transition: all .3s ease;
    }

    .views-field-field-afbeelding .field-content,
    .views-field-field-afbeeldingen .field-content {
      overflow: hidden;
      display: block;
      line-height: 0;
    }

    .views-field-title .field-content {
      color: $black;
      font-size: 18px;
      padding: 10px 0;
      display: block;
    }

    &:hover {
      text-decoration: none;

      opacity: 1 !important;

      img {
        transform: scale(1.1);
      }
    }
  }
}

//========================================
// infoblok
//========================================
.infowrap {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.infoblok {
  width: 100%;
  padding: 1em 1.5em 1.5em;
  border-radius: $input-border-radius;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 1em 1em 1.5em;
    word-break: break-word;
  }

  &,
  h2,
  h3,
  h4,
  p,
  a,
  li:before {
    color: $white !important;
  }

  h2,
  h3,
  h4 {
    font-size: 22px;
    margin: .5em 0 .8em;
  }

  li {
    padding-bottom: .3em;
    display: inline-block;
  }

  .image {
    height: 160px;
    background-size: cover;
    margin: -1em -1.5em 1em;
    background-color: $gray;
    // border-top-left-radius: 30px;
    // border-top-right-radius: 30px;
  }
}

//========================================
// Isotope
//========================================
.isotope-container,
.isotope-container .isotope-element {
  transition-duration: 0.8s;
}

.isotope-container {
  transition-property: height, width;
}

.isotope-container .isotope-element {
  transition-property: transform, opacity;
}

.isotope .isotope-item.no-transition,
.isotope.no-transition,
.isotope.no-transition .isotope-item {
  transition-duration: 0s;
}

.isotope-options {
  margin: 0;
}

.isotope {
  margin: 20px 0 0;
  display: inline-block;
  width: 100%;

  > .view-content {
    margin: 0 -15px;
  }

}

.isotope-container {
  margin: 0 !important;
  padding: 0 !important;

  .isotope-element {
    width: 25%;
    padding: 0 15px;

    .drie & {
      width: 33.333333333333333%;
    }
    @include breakpoint(medium) {
      float: left!important;
      width: 100%!important;
    }
  }

}

//========================================
// Taxonomy
//========================================
.taxonomy-widget {

  padding: 30px;
  background: #efefef;
  margin-bottom: 30px;

  h2 {
    margin-top: 0;
    color: #001928;
    font-size: 18px;
  }

  ul li {
    list-style-type: square;

    a {
      color: #000;
      text-decoration: none;

      &.active {
        font-weight: 600;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

//========================================
// Anchor: correctie fixed header
//========================================
a.anchor {
  padding-top: 180px;
  margin-top: -180px;
  float: left;
  width: 100%;
  display: block!important;
  visibility: hidden!important;
}

//========================================
// Website berichten
//========================================
.messages {
  float: left;
  width: 100%;
  clear: both;
}

//========================================
// Snelmailer
//========================================
.snelmailer-widget {
  float: left;
  width: 100%;
  margin: 15px 0;

  .btn {
    font-size: 12px;
  }

  .container-error {
    display: none!important;
  }
}

//========================================
// Exposed form
//========================================
.views-exposed-form {
  .section-news &,
  .section-nieuws & {
    border-bottom: 1px solid $gray;
  }

  label {
    font-weight: normal;
  }

  .form-radios {
    width: auto;
    display: inline-block;
  }

  .views-exposed-widget {
    width: 100%;

    input[type='radio'] {
      display: none;
    }

    input[type=radio]:checked + label {
      color: $secondary-color;
    }

    .section-news & .form-item,
    .section-nieuws & .form-item {
      float: left;
      display: inline-block;
    }

    .option {
      color: $gray-dark;
      font-family: $alternative-font;
      font-weight: normal;
      margin-right: 2em;

      @include transition(color);

      &:hover {
        color: $tertiary-color;
      }
    }
  }

}

//========================================
// Paginering
//========================================
.pager {
  // background: $gray-dark;
  margin: 50px 0 0 !important;
  padding: 20px 0;
  text-align: center;

  li {
    background: none;
    padding-left: 0 !important;
    margin-left: 7px !important;
    display: inline-block;
  }

  .pager-current,
  a {
    background: $white;
    padding: 0;
    text-align: center;
    height: 18px;
    display: inline-block;
    line-height: 17px;
    color: $primary-color;
    font-size: 14px;
  }

  .pager-current,
  .pager-item a {
    width: 18px;
  }

  .pager-current {
    border-radius: 2px;
    color: $white;
    background: $secondary-color;
  }

  .pager-item a,
  .pager-first a,
  .pager-last a,
  .pager-next a,
  .pager-previous a {
    border-radius: 2px;
    color: $primary-color;

    &:hover {
      background: $primary-color;
      color: $white;
      text-decoration: none;
    }
  }

  .pager-first a,
  .pager-last a,
  .pager-next a,
  .pager-previous a {
    padding: 0 .5em;
  }
}

//========================================
// Diversen
//========================================
.ajax-progress {
  position: absolute;
  left: 50%;
  bottom: 0;
}

.ajax-progress .throbber {
  background: url('../images/ajax-loader.gif');
  position: absolute;
  height: 11px;
  width: 16px;
  margin: 2px;
  z-index: 10;
}

:focus {
  // Blauwe rand om menu weghalen
  outline: none;
}

.anchor {
  display: block!important;
  content: ' '!important;
  margin-top: -200px !important;
  height: 200px!important;
  visibility: hidden!important;
}

a {
  @include transition(all);
}

//========================================
// Media embed
//========================================
.media_embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 1rem;
  overflow: hidden;

  &.widescreen {
    padding-bottom: 56.34%;
  }

  &.vimeo {
    padding-top: 0;
  }

  embed,
  iframe,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

img {
  height: auto;
}

embed,
img,
object {
  max-width: 100%;
}

img {
  -ms-interpolation-mode: bicubic;
}

#map_canvas img,
.map_canvas img {
  max-width: none!important;
}

.grippie {
  display: none !important;
}

textarea {
  resize: none;
}

.content .btn-back {
  margin-top: 110px;

  @include breakpoint(small) {
    margin-top: 30px;
  }
}

.geolocation-help,
.geolocation-map {
  margin: 0;
}
