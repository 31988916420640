@charset "UTF-8";

@font-face {
  font-family: "ciro-horn";
  src:url("fonts/ciro-horn.eot");
  src:url("fonts/ciro-horn.eot?#iefix") format("embedded-opentype"),
    url("fonts/ciro-horn.woff") format("woff"),
    url("fonts/ciro-horn.ttf") format("truetype"),
    url("fonts/ciro-horn.svg#ciro-horn") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  content: attr(data-icon);
}

[data-icon]:before,
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "ciro-horn" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // WARNING: Do not overwrite!
  vertical-align: -15%;
}


// When updating the icon font, only change the lines below:
// =========================================================

.icon-cross:before {
  content: "\e000";
}
.icon-external-link:before {
  content: "\e001";
}
.icon-facebook:before {
  content: "\e002";
}
.icon-twitter:before {
  content: "\e003";
}
.icon-youtube:before {
  content: "\e004";
}
.icon-linkedin:before {
  content: "\e005";
}
.icon-arrow-left:before {
  content: "\e006";
}
.icon-arrow-right:before {
  content: "\e007";
}
.icon-mail:before {
  content: "\e009";
}
.icon-map-marker:before {
  content: "\e00a";
}
.icon-phone:before {
  content: "\e00b";
}
.icon-search:before {
  content: "\e00c";
}
.icon-caret-right:before {
  content: "\e008";
}
.icon-caret-down:before {
  content: "\e00d";
}
.icon-navicon:before {
  content: "\e00e";
}
